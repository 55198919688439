import React, { useEffect, useRef } from "react";
import {  useHistory } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable";
import EditCell from "./cells/EditCell";

import General from "../../../utils/General";

const TYPE_FILTER = {
  name: {
    api: "type",
    label: "Type",
  },
  values: [
    {
      label: "Marketplace",
      value: "marketplace",
    },
    {
      label: "Standard",
      value: "standard",
    },
  ],
};

const FILTERS = [TYPE_FILTER];

const Partners = (props) => {
  const table = useRef(null);
  const history = useHistory();


  const getColumns = () => {
    return [
      {
        Header: "Created At",
        id: "created_at",
        type: "date",
        format: "%d %b %Y",
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <span className="text-dark text-hover-primary fs-7">
                {moment(partnerObject.created_at).format("DD MMM YYYY")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Name",
        id: "name",
        width: 300,
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">
                  {partnerObject.name}
                </a>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Type",
        id: "type",
        width: 130,
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <span className="text-dark text-hover-primary fs-7">
                {General.toTitleCase(partnerObject.type)}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Companies",
        id: "companies_no",
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <span className="text-dark text-hover-primary fs-7">
                {partnerObject.companies_no}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Stores",
        id: "stores_no",
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <span className="text-dark text-hover-primary fs-7">
                {partnerObject.stores_no}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Last Store Connected",
        id: "last_store_connected_date",
        width: 150,
        type: "date",
        format: "%d %b %Y",
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className="d-flex align-items-center">
              <span className="text-dark text-hover-primary fs-7">
                {
                  partnerObject.last_store_connected_date ?
                    moment(partnerObject.last_store_connected_date).format("DD MMM YYYY")
                  :
                    "-"
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: "",
        id: "",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let partnerObject = rowInfo.original;
          return (
            <div className={"my-auto float-end"}>
              <EditCell
                tooltip={"Edit"}
                onEditClicked={() => {
                  history.push(`/partners/${partnerObject.id}`);
                }}
              />
            </div>
          );
        },
      },
    ];
  };

  const columns = getColumns();
  const endpoint = `${window.Api.Partner}`;

  return (
    <>
      <BaseTable
        ref={table}
        endpoint={endpoint}
        noDataMessage={"No partners found"}
        title={props.title}
        columns={columns}
        filters={FILTERS}
        objects={"active"}
        defaultSorted={[
          {
            id: "created_at",
            desc: true,
          },
        ]}
        renderToolbar={() => (
          <>
            <button
              type="button"
              className="btn btn-primary ms-5"
              onClick={() => history.push("/partners/add")}
            >
              + Add Partner
            </button>
          </>
        )}
      />
    </>
  );
};

Partners.defaultProps = {
  title: "Partners",
};

export default Partners;
