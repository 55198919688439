import React from "react";

import LinearProgress from '@material-ui/core/LinearProgress'

export default class Card extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {

    return (
      <>
        <div className={this.props.className}>
          {
            this.props.showHeader &&
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">{this.props.title}</span>
                {
                  this.props.subtitle &&
                  <span className="text-muted mt-1 fw-semibold fs-7">{this.props.subtitle}</span>
                }
              </h3>
              <div
                className="card-toolbar"
              >
                { this.props.renderFilter() }
                {
                  this.props.showToolbar && this.props.renderToolbar()
                }
              </div>

            </div>
          }
          {
            this.props.loading &&
            <LinearProgress />
          }
          <div className={this.props.bodyClassName}>
            { this.props.children }
          </div>
        </div>
      </>
    )

  }
}

Card.defaultProps = {
  className: "card my-5 mb-xl-8",
  bodyClassName: "card-body py-3",
  showExport: true,
  showHeader: true,
  renderToolbar: () => null,
  renderFilter: () => null
}
