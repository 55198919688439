import React from "react";
import Modal from "react-bootstrap/Modal";

const ShopifyHelp = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered mw-550px"}
      >
        <Modal.Header>
          <h2 className="w-100">Shopify Help</h2>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label h2 fw-bolder text-dark fs-6">
            Follow the instructions below to find your Shopify URL.
          </label>
          <br />
          <img
            alt="Logo"
            src={require("../../../assets/media/misc/how-can-i-find-my-shopify-store-url.png")}
            className="h-200px mt-10 mb-10"
          />
          <div className="text-muted text-start mb-4">
            Your Shopify store URL will be in the format
            [your-shop-name].myshopify.com and is the default URL provided to
            you by Shopify when you first created your store. <br />
            <br />
            If you can’t remember your shop URL you can find it by logging into
            your Shopify admin.
          </div>
          <div className="text-end pt-10">
            <button className="btn btn-light me-3" onClick={onHide}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShopifyHelp;
