export default class Currency {

  static format(symbol, amount){
    if(!amount) return `${symbol ? symbol : ''}0.00`
    amount = Number.parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return `${symbol ? symbol : ''}${amount ? amount : ''}`
  }

  static display(symbol, amount){
    return `${symbol}${amount}`
  }

  static toCurrency(value){
    if(value == null){
      return 0.00
    }
    value = (value * 1)
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

}
