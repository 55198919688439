import React, { useEffect } from "react";

const FilingOverview = ({
  period,
  transactionsCount,
  salesCount,
  salesTax,
  purchaseCount,
  purchasesTax,
  fillColor,
}) => {
  useEffect(() => {
    document.querySelector(":root")?.style?.setProperty("--default-fill-color", `${fillColor}`);
  }, [fillColor]);

  return (
    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1">
      {/* Tax Period */}
      <div className="d-flex align-items-center mb-6">
        <div className="symbol symbol-45px w-40px me-5">
          <span className="symbol-label bg-lighten">
            <span className="svg-icon-2x">
              <i class="bi bi-calendar3-fill fs-2 t-fill-color" />
            </span>
          </span>
        </div>
        <div className="d-flex align-items-center flex-wrap w-100">
          <div className="mb-1 pe-3 flex-grow-1">
            <p className="fs-5 text-gray-800 text-hover-primary fw-bolder m-0">
              Tax Period
            </p>
            <div className="text-gray-400 fw-bold fs-7">
              {transactionsCount} Transactions
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="fw-bolder fs-5 text-gray-800 pe-1">{period}</div>
          </div>
        </div>
      </div>
      {/* Tax On Sales*/}
      <div className="d-flex align-items-center mb-6">
        <div className="symbol symbol-45px w-40px me-5">
          <span className="symbol-label bg-lighten">
            <span className="svg-icon-2x">
              <i class="bi bi-basket-fill fs-2 t-fill-color" />
            </span>
          </span>
        </div>
        <div className="d-flex align-items-center flex-wrap w-100">
          <div className="mb-1 pe-3 flex-grow-1">
            <p className="fs-5 text-gray-800 text-hover-primary fw-bolder m-0">
              Tax On Sales
            </p>
            <div className="text-gray-400 fw-bold fs-7">{salesCount} Sales</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="fw-bolder fs-5 text-gray-800 pe-1">{salesTax}</div>
            <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
              <i
                className="bi bi-arrow-up-short fs-2 text-success"
              />
            </span>
          </div>
        </div>
      </div>

      {/* Tax On Purchases */}
      <div className="d-flex align-items-center mb-6">
        <div className="symbol symbol-45px w-40px me-5">
          <span className="symbol-label bg-lighten">
            <span
              className="svg-icon-2x"
              style={{ rotate: "90deg", position: "absolute", right: "8px" }}
            >
              <i className="bi bi-tag-fill fs-1 t-fill-color" />
            </span>
          </span>
        </div>
        <div className="d-flex align-items-center flex-wrap w-100">
          <div className="mb-1 pe-3 flex-grow-1">
            <p className="fs-5 text-gray-800 text-hover-primary fw-bolder m-0">
              Tax On Purchases
            </p>
            <div className="text-gray-400 fw-bold fs-7">
              {purchaseCount} Purchases
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="fw-bolder fs-5 text-gray-800 pe-1">
              {purchasesTax}
            </div>
            <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
              <i
                className="bi bi-arrow-down-short fs-2 text-danger"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilingOverview;

FilingOverview.defaultProps = {
  period: "Apr - Jun",
  transactionsCount: 64,
  salesCount: 32,
  salesTax: "£34.85",
  purchaseCount: 32,
  purchasesTax: "£35.54",
  fillColor: "gray",
};
