import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthManager from '../utils/AuthManager'
import ProtectedRoute from './ProtectedRoute'

export default class UnauthenticatedRoute extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      fallbackUrl: "/"
    }
  }

  _isProtected(){
    return AuthManager.silentLogin()
    .then(user => {
      // TO:DO change fallbackUrl depending on user types if app ever supports
      // other types of users.
      return false
    })
    .catch(error => {
      return true;
    })

  }

  render(){
    return (
      <ProtectedRoute
        {...this.props}
        isProtected={() => this._isProtected()}
        fallbackUrl={this.state.fallbackUrl}
      />
    )
  }
}
