import React from "react"

import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import Stats from "./store/Stats";
import IngestionsTable from "../../components/tables/Ingestions";
import ProblematicProducts from "./store/ProblematicProducts";
import Card from "./store/Card";

export default class Stores extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      integrations: [],
      problematicProducts: [],
      selectedIntegration: null,
      companyId: props.companyId,
      hideProblematicProducts: false,
    }
  }

  componentDidMount() {
    this._getIntegrations().then(integrations => this.setState({integrations}, () => {
      if(integrations.length > 0){
        this.setState({selectedIntegration: integrations[0]})
        this._getIntegration(integrations[0])
      }
    }))
  }

  _getIntegrations(){
    return Backend.getIntegrations(this.state.companyId, 'shop').then(integrations => {
      return integrations
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _getIntegration(integration){
    Backend.getIntegration(integration).then(selectedIntegration => {
      this.setState({selectedIntegration})
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      stats,
      companyId,
      integrations,
      selectedIntegration,
      hideProblematicProducts
    } = this.state

    if(selectedIntegration?.type === "amazon_file"){
      hideProblematicProducts = true
    }

    return (
      <>

        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

          <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">

            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 "></div>


            <div className="d-flex align-items-center gap-2 gap-lg-3">

              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold ">
                {
                  integrations.map(integration => {
                    let className = integration.id === selectedIntegration?.id ? "active" : ""
                    return (
                      <li className="nav-item">
                        <a
                          className={`nav-link text-active-primary pb-4 ${className}`}
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            this._getIntegration(integration)
                          }}
                        >
                          {integration.name}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

          </div>

        </div>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="store_1" role="tabpanel">
            <div className="row">
              <div className="col-xl-6">

                <Card
                  integration={selectedIntegration}
                />

                {
                  !hideProblematicProducts &&
                  <Stats
                    integration={selectedIntegration}
                  />
                }

              </div>
              <div className="col-xl-6">
                {
                  hideProblematicProducts ?
                    <Stats
                      integration={selectedIntegration}
                    />
                    :
                    <ProblematicProducts
                      companyId={companyId}
                      integration={selectedIntegration}
                      onEmpty={() => this.setState({hideProblematicProducts: true})}
                    />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <IngestionsTable
                  key={selectedIntegration?.id}
                  integration={selectedIntegration}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

}
