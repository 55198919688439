import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone"

export default class Receipt extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      type: props.type,
      filing: props.filing,
      files: []
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _uploadDocument(){
    let {
      type,
      files,
      filing
    } = this.state

    if(files.length < 1){
      Notify.error("Please select the files you wish to upload.")
      return
    }

    if(type === "receipt"){
      let paymentStatus = parseFloat(filing.net) > 0 ? "paid" : "refunded"

      let data = {
        filing: filing.id,
        status: "committed",
        payment_status: paymentStatus,
        file: files[0],
        adjustments: []
      }

      this._submitFiling(data)
    }else{
      let data = {
        filing: filing.id,
        status: "committed",
        proof: files[0],
        filing_status: "filed",
        adjustments: []
      }

      this._submitFiling(data)
    }
  }

  _submitFiling(data){
    let {
      type,
    } = this.state

    let message = type === "receipt" ? 'Receipt Uploaded' : 'Filing Submitted'

    Backend.submitFiling(data).then(response => {
      Notify.success(message)
      this.setState({loading: false, files: []})
      this.props.onUploaded(response.latest_filing_data)
    }).catch(e => {
      Notify.error(e.message)
      this.setState({loading: false})
    })
  }

  render() {
    let {
      type,
      show,
      files,
      filing,
      loading
    } = this.state

    let title = type === "receipt" ? 'Receipt' : 'Proof'

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          dialogClassName={'modal-dialog-centered modal-md'}
        >
          <Modal.Header>
            <Modal.Title>Upload {title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="row">

              {
                title === "Proof" &&
                <div className="alert alert-warning" role="alert">
                  <p className="text-muted mb-0">
                    Note: Any foreign currency transactions will be converted to the
                    filing's currency on the period end date.
                  </p>
                </div>
              }

              <Dropzone
                accept="image/jpg,image/jpeg,image/png,application/pdf"
                endpoint={window.Api.Files}
                params={{
                  company: filing.company?.id || filing.company,
                }}
                type={"document"}
                onUploaded={file => {
                  files.push(file.id)
                  this.setState({ files })
                }}
                onUploadStarted={() => {
                  this.setState({ loading: true })
                }}
                onUploadFinished={() => {
                  this.setState({ loading: false })
                }}
                onRemoved={file => {
                  const index = files.indexOf(file.id)
                  if(index > -1) {
                    files.splice(index, 1)
                    this.setState({files})
                  }
                }}
              />
            </div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading}
              onClick={() => this._uploadDocument()}
            >
              Upload
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}
