import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from "./BaseTable"
import LineItems from "./LineItems"
import General from "../../../utils/General";
import AddCell from "./cells/AddCell";
import DeleteCell from "./cells/DeleteCell";
import DocumentCell from "./cells/DocumentCell";
import Digitise from "../modals/Digitise";
import DocumentModal from "../modals/Document";
import revisions from "./Revisions";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import ViewCell from "./cells/ViewCell";
import Document from "./cells/DocumentCell";
import FlagCell from "./cells/FlagCell";
import Report from "../modals/Report";
import Permissions from "../../../utils/Permissions";
import EditCell from "./cells/EditCell";
import Tax from "../modals/Tax";
import FilingSchedule from "../modals/FilingSchedule";
import ConfirmationModal from "../modals/Confirmation";

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "Local",
      value: "local"
    },
    {
      label: "OSS",
      value: "oss"
    },
    {
      label: "IOSS",
      value: "ioss"
    }
  ]
}

const FILTERS = [TYPE_FILTER]

class FilingSchedules extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: FILTERS,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this.table.current.refresh())
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Type',
        id: 'type',
        Cell: rowInfo => {
          let filingSchedule = rowInfo.original

          let className = "badge-light-success"

          if(filingSchedule.type === "oss"){
            className = "badge-light-warning"
          }else if(filingSchedule.type === "ioss"){
            className = "badge-light-primary"
          }

          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ filingSchedule.type.toUpperCase() }
						</span>
          )
        }
      },
      {
        Header: 'Country',
        id: 'country_code',
        Cell: rowInfo => {
          let filingSchedule = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{filingSchedule.country_code}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        Cell: rowInfo => {
          let filingSchedule = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{General.snakeCaseToTitleCase(filingSchedule.frequency)}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let filingSchedule = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                Permissions.hasEditPermission(Permissions.TAXES) &&
                <EditCell
                  tooltip={"Edit"}
                  onEditClicked={() => {
                    this.setState({
                      showFilingScheduleModal: true,
                      selectedFilingSchedule: filingSchedule,
                    })
                  }}
                />
              }

              {
                Permissions.hasDeletePermission(Permissions.TAXES) &&
                <DeleteCell
                  tooltip={"Delete"}
                  iconClass={"la-trash"}
                  onDeleteClicked={() => {
                    this.setState({
                      showConfirmationModal: true,
                      selectedFilingSchedule: filingSchedule,
                    })
                  }}
                />
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteFilingSchedule() {
    let { selectedFilingSchedule } = this.state

    this.setState({loading: true})

    Backend.deleteFilingSchedule(selectedFilingSchedule)
      .then((response) => {
        Notify.success('Filing schedule has been deleted')
        this.table.current.refresh()
        this.setState({
          loading: false,
          selectedFilingSchedule: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteFilingSchedule()
        }}
        onHide={() => {
          this.setState({
            selectedFilingSchedule: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }


  render() {
    let {
      type,
      filters,
      company,
      selectedFilingSchedule,
      showFilingScheduleModal,
      selectedDocument,
      showDocumentModal,
      showDigitiseModal
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.FilingSchedules}`

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No Filing Schedules found"}
          title={this.props.title}
          columns={columns}
          objects={'active'}
          filters={filters}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              {
                Permissions.hasCreatePermission(Permissions.TAXES) &&
                <button
                  type="button"
                  className="btn btn-primary ms-5"
                  onClick={() => this.setState({showFilingScheduleModal: true})}
                >
                  + Add Schedule
                </button>
              }
            </>
          )}
        />
        {
          showFilingScheduleModal &&
          <FilingSchedule
            filingSchedule={General.clone(selectedFilingSchedule)}
            show={showFilingScheduleModal}
            onHide={() => this.setState({showFilingScheduleModal: false, selectedFilingSchedule: null})}
            onSuccess={() => {
              this.table.current.refresh()
              this.setState({showFilingScheduleModal: false, selectedFilingSchedule: null})
            }}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )

  }

}

FilingSchedules.defaultProps = {
  title: "Filing Schedules",
  high_risk: false,
  allocated: null,
  showLineItems: true
}

export default withRouter(FilingSchedules);
