import React from "react";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import Select from "../common/Select";

const TARGET_OPTIONS = [
  {
    label: "Tax",
    value: "tax"
  },
  {
    label: "Total",
    value: "total"
  },
  {
    label: "Subtotal",
    value: "subtotal"
  },
  {
    label: "Issued At",
    value: "issued_at"
  },
  {
    label: "Customer/Supplier",
    value: "entity"
  },
  {
    label: "Tax Rate",
    value: "tax_rate"
  },
  {
    label: "Tax Number",
    value: "tax_number"
  }
]

const COMPARISON_OPTIONS = [
  {
    label: "Equals",
    value: "equals",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "Not Equals",
    value: "not_equals",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "Greater Than",
    value: "greater_than",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "Greater Than Or Equals",
    value: "greater_than_or_equals",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "Less Than",
    value: "less_than",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "Less Than Or Equals",
    value: "less_than_or_equals",
    target: ["tax", "total", "subtotal", "tax_rate", "issued_at"]
  },
  {
    label: "is Business",
    value: "business",
    target: ["entity"]
  },
  {
    label: "is Customer",
    value: "customer",
    target: ["entity"]
  },
  {
    label: "Unrecognised",
    value: "unrecognised",
    target: ["tax_rate", "tax_number"]
  },
]

const ENTITY_VALUE_OPTIONS = [
  {
    label: "True",
    value: "true"
  },
  {
    label: "False",
    value: "false"
  },
]

const TARGET_GROUP_MONETARY = ["tax", "total", "subtotal"]
const TARGET_GROUP_TAX_RATE = ["tax_rate"];
const TARGET_GROUP_TAX_NUMBER = ["tax_number"];
const TARGET_GROUP_ENTITY = ["entity"];
const TARGET_GROUP_ISSUED_AT = ["issued_at"];

export default class RuleInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      rule: props.rule,
    }
  }


  render() {
    let {
      rule,
    } = this.state

    let disableValue = false
    if(!rule.target){
      disableValue = true
    }else if(TARGET_GROUP_ENTITY.includes(rule.target)){
      disableValue = true
    }else if(rule.comparison === "unrecognised"){
      disableValue = true
    }

    let options = []

    COMPARISON_OPTIONS.map(option => {
      if(option.target.includes(rule.target)){
        options.push(option)
      }
    })

    let value = rule.value

    if(TARGET_GROUP_TAX_RATE.includes(rule.target)){
      value = rule.value * 100 || ''
    }

    return (
      <>
        <div className="col">
          <label className="fw-semibold fs-6 mb-2">Target</label>
          <Select
            value={rule.target}
            isDisabled={!!rule.id}
            options={TARGET_OPTIONS}
            placeholder={'Target'}
            getOptionLabel={target => target.label}
            getOptionValue={target => target.value}
            className="filter-solid custom-async-select__container"
            classNamePrefix="custom-async-select"
            onSelected={option => {
              if(option) {
                rule.target = option.value
                rule.comparison = ''
              }
              this.props.onUpdated(rule)
            }}
          />
        </div>

        <div className="col">
          <label className="fw-semibold fs-6 mb-2">Comparison</label>
          <Select
            value={rule.comparison}
            options={options}
            placeholder={'Comparison'}
            getOptionLabel={comparison => comparison.label}
            getOptionValue={comparison => comparison.value}
            className="filter-solid custom-async-select__container"
            classNamePrefix="custom-async-select"
            onSelected={option => {
              if(option) {
                rule.comparison = option.value
              }
              this.props.onUpdated(rule)
            }}
          />
        </div>

        <div className="col">
          <label className="fw-semibold fs-6 mb-2">Value</label>
          {
            rule.target === "issued_at" ?
              <>
                <Flatpickr
                  value={moment(rule.value).format("YYYY-MM-DD") || null}
                  className="form-control form-control-solid"
                  placeholder="From"
                  options={{
                    static: true,
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                  }}
                  onChange={(dates) => {
                    rule.value = moment(dates[0]).format("YYYY-MM-DD")
                    this.props.onUpdated(rule)
                  }}
                />
              </>
              :
                rule.target === "entity" ?
                  <>
                    <Select
                      value={rule.value}
                      options={ENTITY_VALUE_OPTIONS}
                      placeholder={'Value'}
                      getOptionLabel={comparison => comparison.label}
                      getOptionValue={comparison => comparison.value}
                      className="filter-solid custom-async-select__container"
                      classNamePrefix="custom-async-select"
                      onSelected={option => {
                        if(option) {
                          rule.value = option.value
                        }
                        this.props.onUpdated(rule)
                      }}
                    />
                  </>
                :
                  <>
                    <input
                      type="text"
                      name="user_name"
                      placeholder={"Value"}
                      className="form-control form-control-solid pb-2 mpt-2"
                      value={value}
                      disabled={disableValue}
                      onChange={e => {
                        if(TARGET_GROUP_MONETARY.includes(rule.target)) {
                          rule.value = e.target.value
                        }else if(TARGET_GROUP_TAX_RATE.includes(rule.target)) {
                          rule.value = e.target.value/100
                        }else{
                          rule.value = e.target.value
                        }
                        this.props.onUpdated(rule)
                      }}
                    />
                  </>
          }
        </div>
      </>
    )
  }

}
