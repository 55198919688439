import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{window.General.Branding?.platform_name}</title>
      <meta name="og:title" content={window.General.Branding?.platform_name} />
      <meta name="twitter:title" content={window.General.Branding?.platform_name} />
      <meta name="description" content={window.General.Branding?.platform_name} />
      <meta name="og:description" content={window.General.Branding?.platform_name} />
      <meta name="twitter:description" content={window.General.Branding?.platform_name} />
      <link rel="icon" href={window.General.Branding?.favicon?.original} />
      <meta name="type" content="website" />
    </Helmet>
  );
};

export default MetaTags;
