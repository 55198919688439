import React from "react"
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import UserModal from "../modals/User";
import ConfirmationModal from "../modals/Confirmation";

import BaseTable from "./BaseTable"
import EditCell from "./cells/EditCell";
import DeleteCell from "./cells/DeleteCell";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class CompanyMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      type: props.type
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'User',
        id: 'name',
        accessor: 'user.first_name',
        Cell: rowInfo => {
          let teamMember = rowInfo.original
          let user = teamMember.user
          let title = user.first_name + " " + user.last_name
          let subtitle = user.email

          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: teamMember => moment(teamMember.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
    ]

    return columns
  }

  _deleteTeamMember(){
    let {
      type,
      teamMember,
    } = this.state

    Backend.deleteTeamMember(teamMember, type).then(() => {
      Notify.success('Member has been deleted successfully')
      this.setState({
        teamMember: null,
        showConfirmationModal: false
      })
      this.list.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        teamMember: null,
        showConfirmationModal: false
      })
    })
  }

  _renderTeamMemberModal(){
    let { type, teamMember, showTeamMemberModal } = this.state

    return (
      <UserModal
        show={showTeamMemberModal}
        type={type}
        teamMember={General.clone(teamMember)}
        onHide={() => this.setState({ showTeamMemberModal: false, teamMember: null })}
        onAdded={(member) => {
          this.table.current.refresh()
          this.setState({ showTeamMemberModal: false, teamMember: null })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        onConfirm={() => this._deleteTeamMember()}
        onHide={() => this.setState({ showConfirmationModal: false, teamMember: null })}
      />
    )
  }

  render() {
    let {
      type
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.CompanyMembers}?company_id=${this.props.companyId}`}
          noDataMessage={"No company members found"}
          title={"Team Members"}
          objects={'active'}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
        {this._renderTeamMemberModal()}
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(CompanyMembers);
