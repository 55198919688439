import React from "react"
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AsyncStorage from "../../../utils/AsyncStorage";

export default class Platform extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    let code = General.getUrlParameter("code")

    if(code){
      this._finaliseXeroConnection(code)
    }else{
      this._getSettings()
    }
  }

  _getSettings(){
    this.setState({isLoading: true})
    Backend.getSettings().then(settings => {
      this.setState({isLoading: false})
      let settingsData = settings.results
      this.setState({settingsData})
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _finaliseXeroConnection(code){
    AsyncStorage.getItem('xero_data').then(xeroData => {
      xeroData = JSON.parse(xeroData)
      let data = {
        ...xeroData.setting,
        code,
        return_url: xeroData.return_url
      }

      this.setState({isLoading: true})

      Backend.updateSetting(data).then(updatedSetting => {
        Notify.success("Xero Account Connected!")
        this._getSettings()
        this.setState({isLoading: false})
      }).catch(e => {
        this._getSettings()
        this.setState({isLoading: false})
        Notify.success(e.message)
      })
    })
  }

  _updateSetting(setting, index){
    let {
      settingsData,
    } = this.state

    Backend.updateSetting(setting)
      .then(updatedSetting => {
        Notify.success('Settings Updated!')
        settingsData[index] = updatedSetting
        this.setState({
          loading: false,
          settingsData
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        Notify.error(error.message)
      })
  }

  _connectXero(setting, index){
    this.setState({isLoading: true})
    Backend.connectXero(setting).then(response => {
      AsyncStorage.setItem('xero_data', JSON.stringify({setting, return_url: window.location.href}))
      window.location = response.redirect_url
    }).catch(e => {
      Notify.error(e.message)
      this.setState({isLoading: false})
    })
  }

  _renderSettings(setting, index){
    let {
      isLoading,
      settingsData,
    } = this.state

    return (
      <input
        type="text"
        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
        placeholder={General.snakeCaseToTitleCase(setting.key)}
        disabled={isLoading}
        value={setting.value}
        onChange={e => {
          settingsData[index].value = e.target.value
          this.setState({settingsData})
        }}
        onBlur={() => this._updateSetting(setting, index)}
      />
    )
  }

  _renderXeroConnector(setting, index){
    let {
      isLoading,
    } = this.state

    if(setting.value === "not_connected"){
      return (
        <button
          className="btn btn-light-primary align-self-center"
          disabled={isLoading}
          onClick={() => this._connectXero(setting, index)}
        >
          Connect Xero
        </button>
      )
    }else{
      return (
        <div className="col-lg-8 d-flex align-items-center mt-3">
          <span className="fw-bolder fs-6 text-gray-800 me-2">Connected</span>
        </div>
      )
    }
  }

  render() {
    let {
      isLoading,
      settingsData,
    } = this.state

    let className = ""

    if(isLoading){
      className = "overlay overlay-block"
    }

    return (
      <>
        <div className={`card mb-5 mb-xl-10 ${isLoading && className}`} id="kt_profile_details_view">
          <div className="card-header border-0">
            <div className="card-title m-0" bis_skin_checked="1">
              <h3 className="fw-bold m-0">Platform</h3>
            </div>
          </div>
          <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate="novalidate">
            <div className={`card-body border-top p-9 ${isLoading && 'h-500px'}`} bis_skin_checked="1">
              {
                settingsData && settingsData.map((setting, index) => {
                  return (
                    <div className="row mb-6" bis_skin_checked="1">
                      <label className="col-lg-4 col-form-label fw-semibold fs-6">{General.snakeCaseToTitleCase(setting.key)}</label>
                      <div className="col-lg-8" bis_skin_checked="1">
                        <div className="row" bis_skin_checked="1">
                          <div className="col-lg-6 fv-row fv-plugins-icon-container" bis_skin_checked="1">
                            {
                              setting.key === "xero_token" ?
                                this._renderXeroConnector(setting, index)
                              :
                                this._renderSettings(setting, index)
                            }
                            <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </form>
        </div>
      </>
    )
  }

}
