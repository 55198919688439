import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";
import CountrySelect from "../common/CountrySelect";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import ImageEdit from "../common/ImageEdit";
import Calculations from "../../../utils/Calculations";
import Currency from "../../../utils/Currency";

export default class Package extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      selectedIndex: props.selectedIndex,
      packageObject: props.packageObject || {benefits: [], next_invoice_prefix: 'INV_', on_platform: true, next_invoice_sequence_no: 1}
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      packageObject
    } = this.state

    let error = null

    if (packageObject.name == null || packageObject.name.length === 0) {
      error = "Please enter a package name"
    } else if (packageObject.title == null || packageObject.title.length === 0) {
      error = "Please enter a package title"
    } else if (!packageObject.cta) {
      error = "Please enter a CTA"
    } else if (!packageObject.currency) {
      error = "Please select a currency"
    } else if (packageObject.total < 0) {
      error = "Please enter a valid price"
    } else if (!packageObject.frequency) {
      error = "Please select a frequency"
    } else if (packageObject.free_trial_days < 0) {
      error = "Please enter a valid free trial period"
    } else if (packageObject.benefits.length < 0) {
      error = "Please add some benefits"
    } else if (packageObject.included_transactions_no && !packageObject.transaction_threshold_charge) {
      error = "Please set the price per transaction"
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _onAddClick(){
    let {
      packageObject
    } = this.state

    if(!this._isValid()){
      return
    }

    this.setState({loading: true})

    this._handleBackend(packageObject).then(response => {
      this.setState({loading: false})
      Notify.success("Package Saved")
      this.props.onSuccess()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _handleBackend(packageObject){
    if(packageObject.id){
      return Backend.updatePackage(packageObject)
    }
    else{
      return Backend.addPackage(packageObject)
    }
  }

  _renderBenefit(item, index){
    let {
      packageObject,
    } = this.state

    return (
      <>
        <div className="row fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">

          <div className="col">
            <label className="fw-semibold fs-6 mb-2">Text</label>
            <input
              type="text"
              className="form-control form-control-solid mb-3 mb-lg-0"
              value={item.title}
              onChange={e => {
                packageObject.benefits[index].title = e.target.value
                this.setState({packageObject})
              }}
            />
          </div>

          <div className="col-1">
            <a
              className="btn btn-icon btn-sm btn-light-danger me-2 mt-10"
              onClick={e => {
                packageObject.benefits.splice(index, 1)
                this.setState({packageObject})
              }}
            >
              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="" data-bs-original-title="Delete">
                <i className="fa fa-trash"></i>
              </span>
            </a>
          </div>

        </div>
      </>
    )
  }

  render() {
    let {
      show,
      packageObject,
      loading,
      standardForms,
      authorisedForms
    } = this.state

    let title = this.props.packageObject ? 'Update' : 'Add'

    let currency = packageObject.currency ? {value: packageObject.currency.id, label: packageObject.currency.name, data: packageObject.currency} : null
    let percentage = null
    if(packageObject.rate){
      percentage = Math.round((packageObject.rate * 100) * 100) / 100 || ''
    }else if(packageObject.tax_rate){
      percentage = Math.round((packageObject.tax_rate.rate * 100) * 100) / 100 || ''
    }
    let vatEnabled = percentage > 0 || packageObject.enableVat

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-900px"}
        >
          <Modal.Header>
            <h2 className="w-100">{title} Package</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Name</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Name"
                    value={packageObject.name}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.name = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Title</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Title"
                    value={packageObject.title}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.title = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Subtitle</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Subtitle"
                    value={packageObject.subtitle}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.subtitle = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Description</label>
                <div className="position-relative">
                  <textarea
                    rows="2"
                    placeholder="Package description"
                    value={packageObject.description}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.description = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">CTA</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="CTA"
                    value={packageObject.cta}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.cta = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="c-separator form-group row my-4 align-items-center text-center">
                <div className="col-3">
                  <hr/>
                </div>
                <div className="col-6"><span><strong>Invoicing</strong></span></div>
                <div className="col-3">
                  <hr/>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">On Platform Billing</label>
                <div className="position-relative">
                  <Select
                    value={packageObject.on_platform}
                    options={[
                      {
                        label: "Yes, Issue Invoices To Companies",
                        value: true
                      },
                      {
                        label: "No, Don't Issue Invoices To Companies",
                        value: false
                      }
                    ]}
                    placeholder={"On Platform Billing"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      packageObject.on_platform = type.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="c-separator form-group row my-4 align-items-center text-center">
                <div className="col-3">
                  <hr/>
                </div>
                <div className="col-6"><span><strong>Pricing</strong></span></div>
                <div className="col-3">
                  <hr/>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Currency</label>
                <div className="position-relative">
                  <AsyncSelect
                    endpoint={window.Api.Currencies}
                    orderBy={"name"}
                    className="form-control h-auto border-0 form-control-solid c-selectbox"
                    placeholder={'Select Currency'}
                    value={currency}
                    onSelected={(currency) => {
                      packageObject.currency = currency
                      this.setState({ packageObject })
                    }}
                    getOptions={(currencies) => {
                      return currencies.map((currency) => ({
                        value: currency.id,
                        label: currency.name,
                        data: currency,
                      }))
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-1 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Price</label>
                <div className="position-relative">
                  <input
                    type="number"
                    value={packageObject.total}
                    placeholder="0.00"
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject["total"] = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <form className="form mb-7">
                <div className="form-group row">
                  <label className="col-2 col-form-label">Includes TAX</label>
                  <div className="col-2">
                    <span className="switch">
                      <label>
                        <input
                          type="checkbox"
                          checked={packageObject.enableVat || vatEnabled}
                          onChange={e => {
                            let show = e.target.checked
                            if(show){
                              packageObject.enableVat = show
                            }else{
                              packageObject.enableVat = show
                              packageObject.rate = null
                              packageObject.tax_rate = null
                            }
                            this.setState({ packageObject })
                          }}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  {
                    vatEnabled &&
                    <>
                      <div className="col-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{border: 'none'}}>%</span>
                          </div>
                          <input
                            className="form-control form-control-solid"
                            type="number"
                            min={0}
                            placeholder="0"
                            name={"vat"}
                            value={percentage}
                            onChange={(e) => {
                              packageObject["rate"] = e.target.value / 100
                              this.setState({ packageObject})
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                </div>
              </form>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Frequency</label>
                <div className="position-relative">
                  <Select
                    value={packageObject.frequency}
                    options={[
                      {
                        label: "Monthly",
                        value: "monthly"
                      },
                      {
                        label: "Yearly",
                        value: "yearly"
                      }
                    ]}
                    placeholder={"Frequency"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      packageObject.frequency = type.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-contatiner">
                <label className="required fs-6 fw-semibold form-label mb-2">Free Trial Days</label>
                <div className="position-relative">
                  <input
                    type="number"
                    placeholder="Included Transactions Threshold"
                    value={packageObject.free_trial_days}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.free_trial_days = e.target.value
                      this.setState({packageObject})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-1">Included Transactions Threshold</label>
                <div className="text-muted text-start mb-2">
                  Leave blank for unlimited.
                </div>
                <div className="position-relative">
                  <input
                    type="number"
                    placeholder="Included Transactions Threshold"
                    value={packageObject.included_transactions_no}
                    className="form-control form-control-solid"
                    onChange={e => {
                      packageObject.included_transactions_no = e.target.value
                      this.setState({packageObject})
                    }}
                    onBlur={e => {
                      if(e.target.value === "") {
                        packageObject.included_transactions_no = null
                        packageObject.transaction_threshold_charge = null
                        this.setState({packageObject})
                      }
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              {
                packageObject.included_transactions_no !== null &&
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-1">Charge Per Transaction Over Threshold</label>
                  <div className="text-muted text-start mb-2">
                    Leave blank for none.
                  </div>
                  <div className="position-relative">
                    <input
                      type="number"
                      step={0.01}
                      placeholder="Charge Per Transaction Over Threshold"
                      value={parseFloat(packageObject.transaction_threshold_charge)}
                      className="form-control form-control-solid"
                      onChange={e => {
                        packageObject.transaction_threshold_charge = e.target.value
                        this.setState({packageObject})
                      }}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
              }

              <div className="c-separator form-group row my-4 align-items-center text-center">
                <div className="col-3">
                  <hr/>
                </div>
                <div className="col-6"><span><strong>Branding</strong></span></div>
                <div className="col-3">
                  <hr/>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <label className="required fs-6 fw-semibold form-label mb-2">Background Image</label>
                  <div className="position-relative">
                    <ImageEdit
                      type={"portrait"}
                      aspectRatio={0.66}
                      cropImage={true}
                      image={packageObject.background_image?.original}
                      hidePreview={false}
                      deletable={true}
                      onUpdated={image => {
                        packageObject.background_image = image
                        this.setState({packageObject})
                      }}
                      onDeleteClicked={() => {
                        packageObject.background_image = null
                        this.setState({packageObject})
                      }}
                    />
                  </div>
                </div>
                <div className="col-8">
                  <SmartList
                    items={packageObject.benefits}
                    itemName={"Benefit"}
                    draggable={true}
                    renderItem={(item, index) => this._renderBenefit(item, index)}
                    canDelete={false}
                    onAddItemPressed={() => {
                      packageObject.benefits.push({})
                      this.setState({packageObject})
                    }}
                    onUpdated={items => {
                      packageObject.benefits = items
                      this.setState({packageObject})
                    }}
                  />
                </div>
              </div>



              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => this._onAddClick()}
                >
                  <span className="indicator-label">
                    {title}
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
