import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";
import CountrySelect from "../common/CountrySelect";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

export default class FilingSchedule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      filingSchedule: props.filingSchedule || {}
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      filingSchedule
    } = this.state

    let error = null

    if (!filingSchedule.type){
      error = "Please select the TAX type"
    }else if (!filingSchedule.frequency){
      error = "Please set the frequency"
    }

    if(!error){
      if (filingSchedule.type === "local" && !filingSchedule.country_code){
        error = "Please select the country"
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onAddClick(){
    let {
      filingSchedule
    } = this.state

    if(!this._isValid()){
      return
    }

    this.setState({loading: true})

    this._handleBackend(filingSchedule).then(response => {
      this.setState({loading: false})
      Notify.success("Filing Schedule Saved")
      this.props.onSuccess()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _handleBackend(filingSchedule){
    if(filingSchedule.id){
      return Backend.updateFilingSchedule(filingSchedule)
    }
    else{
      return Backend.addFilingSchedule(filingSchedule)
    }
  }

  render() {
    let {
      show,
      filingSchedule,
      loading,
    } = this.state

    let buttonTitle = filingSchedule.id ? 'Update' : 'Add'

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-550px"}
        >
          <Modal.Header>
            <h2 className="w-100">Filing Frequency</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Registration Type</label>
                <div className="position-relative">
                  <Select
                    value={filingSchedule.type}
                    options={[
                      {
                        label: "Local",
                        value: "local"
                      },
                      {
                        label: "OSS",
                        value: "oss"
                      },
                      {
                        label: "IOSS",
                        value: "ioss"
                      }
                    ]}
                    placeholder={"Type"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      filingSchedule.type = type.value
                      if(type.value !== "local") {
                        filingSchedule.country_code = null
                      }
                      this.setState({filingSchedule})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Frequency</label>
                <div className="position-relative">
                  <Select
                    value={filingSchedule.frequency}
                    options={[
                      {
                        label: "Monthly",
                        value: "monthly"
                      },
                      {
                        label: "Bi-Monthly",
                        value: "bi_monthly"
                      },
                      {
                        label: "Quarterly",
                        value: "quarterly"
                      },
                      {
                        label: "Yearly",
                        value: "yearly"
                      }
                    ]}
                    placeholder={"Type"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      filingSchedule.frequency = type.value
                      this.setState({filingSchedule})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              {
                filingSchedule.type === "local" &&
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-2">Country</label>
                  <div className="position-relative">
                    <CountrySelect
                      isEU={true}
                      value={filingSchedule.country_code}
                      onSelected={country => {
                        filingSchedule.country_code = country.value
                        this.setState({filingSchedule})
                      }}
                    />
                  </div>
                </div>
              }

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => this._onAddClick()}
                >
                  <span className="indicator-label">
                    {buttonTitle}
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
