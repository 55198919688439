import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";

export default class User extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      type: props.type,
      teamMember: props.teamMember || General.clone({user:{}, companies: []}),
    }
  }

  _validateName(name){
    const regex = /^[a-zA-Z\s']+$/;
    return regex.test(name);
  };

  _isFormValid() {
    let { id, first_name, last_name, email, password } = this.state.teamMember.user

    let error = null

    if (first_name == null || first_name.length === 0) {
      error = 'Please enter a first name';
    } else if (!this._validateName(first_name)) {
      error = 'First name can only contain letters, spaces, and single quotes';
    } else if (last_name == null || last_name.length === 0) {
      error = 'Please enter a last name';
    } else if (!this._validateName(last_name)) {
      error = 'Last name can only contain letters, spaces, and single quotes';
    } else if (email == null || email.length === 0) {
      error = 'Please enter an email'
    } else if (id == null && password == null) {
      error = 'Please enter a password'
    } else if ((this.state.type === "agent" || this.state.type === "partner-members") && this.state.teamMember.role == null) {
      error = 'Please select a role'
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddMember(){
    if (!this._isFormValid()) {
      return
    }
    if (this.state.teamMember.user.id) {
      this._updateTeamMember()
    } else {
      this._addTeamMember()
    }
  }

  _updateTeamMember(){
    let { type, teamMember } = this.state

    this.setState({ loading: true })
    let data = General.clone(teamMember)

    Backend.updateTeamMember(data, type)
      .then((member) => {
        Notify.success('Member Updated Successfully')
        this.setState({ loading: false })
        this.props.onAdded(member)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _addTeamMember(){
    let { type, teamMember } = this.state

    this.setState({ loading: true })
    let data = General.clone(teamMember)

    Backend.addTeamMember(data, type)
      .then((member) => {
        Notify.success('Member Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(member)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _getOptionsCompanies(companies){
    return companies?.map(company => ({
        label: company.name,
        value: company
      })
    )
  }

  _getOptions(companies){
    return companies.map(company => ({
      value: company.id,
      label: company.name,
      data: company
    }))
  }

  render() {
    let {
      show,
      type,
      teamMember,
    } = this.state

    let role = teamMember.role ? {label: teamMember.role.name} : null

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-650px"}
        >
          <Modal.Header>
            <h2 className="fw-bold">Add User</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column me-n7 pe-7" id="kt_modal_add_user_scroll">

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">First Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First Name"
                    value={teamMember.user.first_name}
                    onChange={(e) => {
                      teamMember.user.first_name = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last Name"
                    value={teamMember.user.last_name}
                    onChange={(e) => {
                      teamMember.user.last_name = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input
                    type="email"
                    name="user_email"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Email"
                    value={teamMember.user.email}
                    disabled={!!teamMember.user.id}
                    onChange={(e) => {
                      teamMember.user.email = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                {
                  !teamMember.user.id &&
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">Password</label>
                    <input
                      type="password"
                      name="user_password"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      placeholder="Password"
                      value={teamMember.user.password}
                      onChange={(e) => {
                        teamMember.user.password = e.target.value
                        this.setState({ teamMember, error: null })
                      }}
                    />
                  </div>
                }

                {
                  (type === "agent" || type === "partner-members") &&
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">Role</label>
                    <div className="select-wrapper">
                      <AsyncSelect
                        endpoint={window.Api.Roles}
                        className="form-control h-auto border-0 form-control-solid c-selectbox"
                        placeholder={'Select Role'}
                        value={role}
                        onSelected={(role) => {
                          teamMember.role = role
                          this.setState({ teamMember, error: null })
                        }}
                        getOptions={(roles) => {
                          return roles.map((role) => ({
                            value: role.id,
                            label: role.name,
                            data: role,
                          }))
                        }}
                      />
                    </div>
                  </div>
                }

                { type === "agent" &&
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className=" fw-semibold fs-6">Assigned Companies</label><br/>
                    <span className="text-muted mb-3">This Agent will be assigned to the companies you select</span>
                    <div className="select-wrapper">
                      <AsyncSelect
                        endpoint={window.Api.Companies}
                        isMulti={true}
                        isClearable={true}
                        className="form-control h-auto border-0 form-control-solid mt-3 c-selectbox"
                        placeholder={'All Companies'}
                        value={this._getOptions(teamMember.companies)}
                        onSelected={(companies) => {
                          teamMember.companies = companies?.map(company => company.data) || []
                          this.setState({ teamMember, error: null })
                        }}
                        getOptions={(companies) => this._getOptions(companies)}
                      />
                    </div>
                  </div>
                }

                <div className="fv-row">

                </div>
              </div>


              <div className="text-end pt-15" bis_skin_checked="1">
                <button
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={e => {
                    e.preventDefault()
                    this.props.onHide()
                  }}
                >
                  Discard
                </button>

                <button
                  className="btn btn-primary"
                  disabled={this.state.loading}
                  onClick={e => {
                    e.preventDefault()
                    this._handleAddMember()
                  }}
                >
                  <span className="indicator-label">Submit</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>

              <div bis_skin_checked="1"></div>
            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
