import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import General from "../utils/General";
import TextInputField from "./components/common/TextInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import AuthManager from "../utils/AuthManager";
import Notify from "../utils/Notify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const resetPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(/@[^.]*\./, 'Invalid email address'),
  password: Yup.string()
    .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, 'Please ensure your password contains letters, numbers and is at least 8 characters long')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('Confirm Password is required'),
});


const ResetPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(resetPasswordValidationSchema),
  });
  const [resetCode, setResetCode] = useState(null);
  const history = useHistory();

  const handleAddClick = (partnerObject) => {
    const { email, password } = partnerObject;
    AuthManager.resetPassword(email, password, resetCode).then(() => {
      Notify.success("Password reset successfully!");
      history.push("/login");
    }).catch((error) => {
      Notify.error(error.message);
    });
  };

  useEffect(() => {
    // Function to decode the encoded code parameter
    function decodeCode(encodedCode) {
      const decodedBytes = atob(encodedCode);
      const codeArray = new Uint8Array(
        decodedBytes.split("").map((char) => char.charCodeAt(0))
      );
      let code = 0;
      codeArray.forEach((byte) => {
        code = (code << 8) | byte;
      });
      return code;
    }
    // Retrieve the encoded code parameter from the URL query string
    const queryParams = new URLSearchParams(window.location.search);
    const encodedCode = queryParams.get("code");
    // Decode the encoded code parameter
    if (encodedCode) {
      const decodedCode = decodeCode(encodedCode);
      setResetCode(decodedCode);
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-600px position-xl-relative bgi-no-repeat bgi-size-cover"
            style={{backgroundImage: `url(${window.General.Branding?.background_image?.original})`}}
          >
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px h-100 branded-login-sidebar">
              <div
                className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20"
                style={{ justifyContent: "center" }}
              >
                <Link to={"/login"} className="py-9 mb-5 text-center">
                  <img
                    alt="Logo"
                    src={window.General.Branding?.logos?.light?.original}
                    className="logo"
                    style={{ width: "60%" }}
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-lg-row-fluid py-10 bg-white">
            <div
              className="d-flex flex-center flex-column flex-column-fluid"
              data-select2-id="select2-data-15-7d58"
            >
              <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                <form onSubmit={handleSubmit(handleAddClick)}>
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Reset Password</h1>
                  </div>

                  <TextInputField
                    isRequired
                    label={"Email"}
                    outerContainerClassName = {"mt-5"}
                    labelClass={"fw-bolder text-dark"}
                    placeholder={"Email"}
                    type={"email"}
                    errorMsg={errors.email?.message}
                    {...register("email")}
                  />
                  <TextInputField
                    isRequired
                    label={"Password"}
                    outerContainerClassName = {"mt-5"}
                    labelClass={"fw-bolder text-dark"}
                    placeholder={"Password"}
                    type={"password"}
                    errorMsg={errors.password?.message}
                    {...register("password")}
                  />
                  <TextInputField
                    isRequired
                    label={"Confirm Password"}
                    outerContainerClassName = {"mt-5"}
                    labelClass={"fw-bolder text-dark"}
                    placeholder={"Confirm Password"}
                    type={"password"}
                    errorMsg={errors.confirm_password?.message}
                    {...register("confirm_password")}
                  />

                  <div className="text-center mt-10">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 fw-bolder mb-5"
                    >
                      Submit
                    </button>
                    <Link to="/login" className="btn btn-light-primary mt-5 d-block">
                      Log In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
