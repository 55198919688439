import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from "./BaseTable"
import LineItems from "./LineItems"
import General from "../../../utils/General";
import AddCell from "./cells/AddCell";
import DeleteCell from "./cells/DeleteCell";
import DocumentCell from "./cells/DocumentCell";
import Digitise from "../modals/Digitise";
import DocumentModal from "../modals/Document";
import revisions from "./Revisions";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import ViewCell from "./cells/ViewCell";
import Document from "./cells/DocumentCell";
import FlagCell from "./cells/FlagCell";
import Report from "../modals/Report";
import Permissions from "../../../utils/Permissions";
import Currency from "../../../utils/Currency";

const COMPANY_FILTER = {
  name: {
    api: 'company_id',
    label: 'Company',
  },
  endpoint: {
    url:`${window.Api.Companies}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const CUSTOMER_FILTER = {
  name: {
    api: 'entity_id',
    label: 'Customer/Supplier',
  },
  endpoint: {
    url:`${window.Api.Entities}`,
    filters: '',
    orderBy: '',
    accessor: ''
  }
}

const STATUS_FILTER = {
  name: {
    api: 'allocated',
    label: 'Status',
  },
  values: [
    {
      label: "Allocated",
      value: "true"
    },
    {
      label: "Unallocated",
      value: "false"
    }
  ]
}

const FILING_STATUS_FILTER = {
  name: {
    api: 'tax_number_id',
    label: 'Status',
  },
  endpoint: {
    url:`${window.Api.TaxNumbers}`,
    filters: '',
    orderBy: 'country_code',
    accessor: ''
  }
}

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "Sale",
      value: "sale"
    },
    {
      label: "Purchase",
      value: "purchase"
    }
  ]
}

const SUB_TYPE_FILTER = {
  name: {
    api: 'subtype',
    label: 'Sub Type',
  },
  values: [
    {
      label: "Invoice",
      value: "invoice"
    },
    {
      label: "Receipt",
      value: "receipt"
    },
    {
      label: "Credit Note",
      value: "credit_note"
    },
    {
      label: "Refund",
      value: "refund"
    }
  ]
}

const SHIP_FROM_FILTER = {
  name: {
    api: 'ship_from',
    label: 'Ship From',
  },
  type: "country"
}

const SHIP_TO_FILTER = {
  name: {
    api: 'ship_to',
    label: 'Ship To',
  },
  type: "country"
}

const FILTERS = [COMPANY_FILTER, CUSTOMER_FILTER, STATUS_FILTER, TYPE_FILTER, SUB_TYPE_FILTER, SHIP_FROM_FILTER, SHIP_TO_FILTER]
const COMPANY_DETAIL_FILTERS = [CUSTOMER_FILTER, FILING_STATUS_FILTER, TYPE_FILTER, SUB_TYPE_FILTER, SHIP_FROM_FILTER, SHIP_TO_FILTER]

class Transactions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: props.companyId ? COMPANY_DETAIL_FILTERS : FILTERS,
      type: props.type,
      revisionId: props.revisionId
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this.table.current.refresh())
  }

  _getColumns(){
    let columns = [
      {
        Header: 'ID',
        id: 'external_id',
        width: 120,
        sortable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          let id = ''
          if(record.external_order_no){
            id = `#${record.external_order_no}`
          }else if(record.external_id){
            id = record.external_id
          }else{
            id = record.reference
          }
          return (
            <div class="d-flex align-items-center">
							<span class="text-dark text-hover-primary fs-7">
                {id}
              </span>
            </div>
          )
        }
      },
      {
        Header: 'Risk',
        id: 'risk_score',
        width: 65,
        Cell: rowInfo => {
          let record = rowInfo.original
          let className = "badge-success"
          if(record.risk_score > 5 && record.risk_score < 8){
            className = "badge-warning"
          }else if(record.risk_score >= 8){
            className = "badge-danger"
          }
          return (
            <Tooltip title={record.rule_state?.description} placement="top" arrow>
              <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
                {record.risk_score}
              </span>
            </Tooltip>
          )
        }
      },
      {
        Header: 'Company',
        id: 'company.name',
        width: 150,
        Cell: rowInfo => {
          let record = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{record.company.name}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{record.company.created_by.user.email}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'filing',
        width: 130,
        Cell: rowInfo => {
          let record = rowInfo.original

          let label = `UNALLOCATED`
          let className = "badge-light-warning"

          if(record.filing){
            className = 'badge-light-dark'
            if(record.filing.type === "oss"){
              className = 'badge-light-info'
            }else if(record.filing.type === "ioss"){
              className = 'badge-light-success'
            }
            if(record.filing.type === "oss" || record.filing.type === "ioss"){
              label = `${record.filing.type.toUpperCase()}`
            }else{
              label = `${record.filing.tax_number.country_code} ${record.filing.type.toUpperCase()}`
            }
          }

          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ label }
						</span>
          )
        }
      },
      {
        Header: 'Amount',
        id: 'total',
        Cell: rowInfo => {
          let record = rowInfo.original
          let symbol = record.currency.symbol
          let negative = (record.subtype === "credit_note" || record.subtype === "refund") ? '-' : ''
          let amount = record.total
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{negative}{Currency.format(symbol, amount)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Tax',
        id: 'tax',
        width: 80,
        Cell: rowInfo => {
          let record = rowInfo.original
          let symbol = record.currency.symbol
          let negative = (record.subtype === "credit_note" || record.subtype === "refund") ? '-' : ''
          let tax = record.tax
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{negative}{Currency.format(symbol, tax)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Rates',
        width: 70,
        sortable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          let taxRate = '0%'
          if(record.tax_rates[0]?.rate){
            taxRate = `${(parseFloat(record.tax_rates[0]?.rate) * 100)?.toFixed(2)?.replace(/\.?0+$/, '')}%`
          }
          let more = `${record.tax_rates.length > 1 ? ` +${record.tax_rates.length-1}` : ''}`
          return (
            <div class="text-center">
              <a class="text-dark text-hover-primary fs-6">{taxRate}&nbsp;</a><span style={{fontSize:12, textAlign: 'justify'}}>{more}</span>
            </div>
          )
        }
      },
      {
        Header: 'Issued At',
        id: 'issued_at',
        accessor: record => moment(record.issued_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Customer/Supplier',
        id: 'entity',
        width: 200,
        Cell: rowInfo => {
          let record = rowInfo.original
          let entity = record.entity
          let title = entity?.first_name ? entity.first_name : "-"
          let subtitle = entity?.email || ""
          if(entity?.last_name){
            title += " " + entity.last_name
          }
          else if(entity.type === "pos_terminal"){
            title = "POS Terminal"
          }
          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Ship',
        width: 65,
        sortable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          let origin = `${record.origin_locations[0]?.country_code || ''} ${record.origin_locations.length > 1 ? `+${record.origin_locations.length-1}` : ''}`
          let destination = record.shipping_address?.country_code || record.billing_address?.country_code || record.entity.country_short || ""
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                {
                  destination &&
                  <>
                    {origin && <span className=" fw-bold fs-7">{`From: ${origin}`}</span>}
                    <span className=" fw-bold d-block fs-7">{`To: ${destination}`}</span>
                  </>
                }
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        id: 'type',
        width: 80,
        Cell: rowInfo => {
          let record = rowInfo.original
          let type = General.toTitleCase(record.type)
          let subtype = General.snakeCaseToTitleCase(record.subtype)
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{type}</a>
                <span className="text-muted fw-bold text-muted d-block fs-7">{subtype}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Source',
        id: 'source',
        width: 90,
        Cell: rowInfo => {
          let record = rowInfo.original
          if(!record.source){
            return "-"
          }
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{General.snakeCaseToTitleCase(record.source)}</a>
              </div>
            </div>
          )
        },
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                record.document &&
                <ViewCell
                  tooltip={"View Document"}
                  onViewClicked={() => {
                    this._getTransaction(record)
                  }}
                />
              }
              {
                (AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.MESSAGES)) &&
                (!this.props.filing?.id) &&
                <FlagCell
                  tooltip={"Report"}
                  onFlagClicked={() => {
                    if(record.conversation) {
                      window.location.href = `/company/${record.company.id}?conversation_id=${record.conversation}#messages`
                    }else {
                      this.setState({
                        selectedRecord: record,
                        showReportModal: true
                      })
                    }
                  }}
                />
              }
              {
                (AuthManager.currentUser.user.role === "agent" && this.props.filing) &&
                <>
                  {
                    this.props.filing &&
                    (!this.props.allocated && Permissions.hasCreatePermission(Permissions.FILINGS)) &&
                    <AddCell
                      tooltip={"Allocate"}
                      onAddClicked={() => {
                        this.setState({selectedRecord: record}, () => {
                          if(this.state.revisionId){
                            this._allocateTransaction()
                          }else{
                            this._createRevision("allocate")
                          }
                        })
                      }}
                    />
                  }
                  {
                    this.props.filing &&
                    (this.props.allocated && Permissions.hasCreatePermission(Permissions.FILINGS)) &&
                    <DeleteCell
                      tooltip={"Unallocate"}
                      iconClass={"la-minus"}
                      onDeleteClicked={() => {
                        this.setState({selectedRecord: record}, () => {
                          if(this.state.revisionId){
                            this._unallocateTransaction()
                          }else{
                            this._createRevision("unallocate")
                          }
                        })

                      }}
                    />
                  }
                </>
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _getTransaction(record){
    Backend.getTransaction(record.id).then(selectedRecord => {
      if(selectedRecord.document.status === "processed"){
        this.setState({
          selectedDocument: selectedRecord.document,
          selectedRecord,
          showDigitiseModal: true
        })
      }else{
        this.setState({
          selectedDocument: selectedRecord.document,
          showDocumentModal: true
        })
      }
    }).catch(e => Notify.error(e.message))
  }

  _createRevision(type){
    let {
      selectedRecord
    } = this.state

    let data = {
      filing: this.props.filing.id,
      adjustments: [
        {
          record: selectedRecord.id,
          type: type
        }
      ]
    }

    Backend.createRevision(data).then(response => {
      Notify.success("Adjustment Saved")
      this.props.onRevisionCreated(response)
    }).catch(e => Notify.error(e.message))
  }

  _allocateTransaction(){
    let {
      revisionId,
      selectedRecord
    } = this.state

    let data = {
      revision: revisionId,
      type: "allocate",
      record: selectedRecord.id
    }

    Backend.createAdjustment(data).then(response => {
      Notify.success("Adjustment Saved")
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _unallocateTransaction(){
    let {
      revisionId,
      selectedRecord
    } = this.state

    let data = {
      revision: revisionId,
      type: "unallocate",
      record: selectedRecord.id
    }

    Backend.createAdjustment(data).then(response => {
      Notify.success("Adjustment Saved")
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _renderDetail(record){
    return (
      <LineItems record={record}/>
    )
  }

  render() {
    let {
      type,
      filters,
      revisionId,
      selectedRecord,
      showReportModal,
      selectedDocument,
      showDocumentModal,
      showDigitiseModal
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.Records}?`

    if(!this.props.high_risk){
      columns.splice(1,1)
    }

    if(type){
      endpoint = `${endpoint}&type=${type}`
      columns.splice(9,1)
    }
    if(this.props.allocated){
      endpoint = `${endpoint}&allocated=${this.props.allocated}`
    }
    if(this.props.unallocated){
      endpoint = `${endpoint}&allocated=${!this.props.unallocated}&company_id=${this.props.filing.company.id}`
    }
    if(this.props.companyId){
      columns.splice(1,1)
      endpoint = `${endpoint}&company_id=${this.props.companyId}`
      filters[1].endpoint.filters = `company_id=${this.props.companyId}`
    }
    if(this.props.filing?.id){
      if(!this.props.unallocated){
        endpoint = `${endpoint}&filing_id=${this.props.filing?.id}`
      }else{
        endpoint = `${endpoint}&target_filing_id=${this.props.filing?.id}`
      }
      columns.splice(1,1)
      columns.splice(1,1)
      columns.splice(8,1)
    }
    if(this.props.high_risk){
      endpoint = `${endpoint}&high_risk=true`
    }
    if(revisionId){
      endpoint = `${endpoint}&simulate_revision_id=${revisionId}`
    }

    let props = {}
    if(this.props.showLineItems){
      props.SubComponent = (row) => this._renderDetail(row.original)
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No transactions found"}
          title={this.props.title}
          columns={columns}
          objects={'active'}
          filters={filters}
          dateRange={true}
          defaultSorted={[
            {
              id: "issued_at",
              desc: true
            }
          ]}
          {...props}
        />
        {
          showDigitiseModal &&
          <Digitise
            show={showDigitiseModal}
            document={selectedDocument}
            record={selectedRecord}
            onHide={() => this.setState({showDigitiseModal: false, selectedRecord: null})}
          />
        }
        {
          showDocumentModal &&
          <DocumentModal
            show={showDocumentModal}
            document={selectedDocument}
            onHide={() => this.setState({showDocumentModal: false, selectedDocument: null})}
          />
        }
        {
          showReportModal &&
          <Report
            show={showReportModal}
            type={"transaction"}
            record={selectedRecord}
            onHide={() => this.setState({showReportModal: false, selectedRecord: null})}
            onReported={() => {
              this.table.current.refresh()
              this.setState({showReportModal: false, selectedRecord: null})
            }}
          />
        }
      </>
    )

  }

}

Transactions.defaultProps = {
  title: "Transactions",
  high_risk: false,
  allocated: null,
  showLineItems: true
}

export default withRouter(Transactions);
