import React from "react"

import AuthManager from "../utils/AuthManager";

import Transactions from "./components/tables/Transactions";

export default class HighRisk extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>
        <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mt-5 mb-5 p-6">
          <i className="ki-duotone ki-information fs-2tx text-warning me-4"><span className="path1"></span><span
            className="path2"></span><span className="path3"></span></i>

          <div className="d-flex flex-stack flex-grow-1 ">
            <div className=" fw-semibold">
              <h4 className="text-gray-900 fw-bold">High Risk Transactions</h4>
              <div className="fs-6 text-gray-700 w-750px">The transactions below have been identified as High Risk. They
                will be allocated into filings unless flagged. Please review each transaction carefully and flag any
                transactions that may be filed incorrectly
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <Transactions title="High Risk" high_risk={true}/>
          </div>
        </div>
      </>
    )
  }

}
