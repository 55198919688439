import React, { Component } from "react";


import TextareaAutosize from 'react-autosize-textarea';

export class LoadingConversation extends Component {
  constructor(props) {
    super(props);


    this.state = {

    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    });
  }

  render() {
    let {
      text,
    } = this.state;

    return (
      <>
        <div className="card " id="kt_chat_messenger">

          <div className="card-header" id="kt_chat_messenger_header">
            <div className="card-title" style={{width: '100%'}}>

              <div className="d-flex justify-content-center flex-column me-3">
                <a
                  href="#"
                  className="menu-link flex-stack px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                />
                <div
                  className="fs-4 fw-bolder text-gray-900 w-350px h-10px mt-5 text-hover-primary me-1 mb-2 lh-1 shimmer"
                  style={{minHeight: 45}}
                />
                <div className="mb-0 lh-1 w-200px mb-3 h-5px shimmer" style={{minHeight: 30}}/>
              </div>
            </div>
          </div>

          <div className="card-body" id="kt_chat_messenger_body">
            <div
              className="scroll scroll-pull ps ps--active-y"
            >
              <div className="messages">
                <div className="d-flex row mb-5 align-items-start shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start  w-350px"/>
                <div className="d-flex row mb-5 align-items-end float-end shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start  w-350px"/>
                <div className="d-flex row mb-5 align-items-end float-end shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start  w-350px"/>
                <div className="d-flex row mb-5 align-items-end float-end shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start w-350px"/>
                <div className="d-flex row mb-5 align-items-start shimmer w-350px"/>
                <div className="d-flex row mb-5 align-items-start  w-350px"/>
                <div className="d-flex row mb-5 align-items-end float-end shimmer w-350px"/>
              </div>
            </div>
          </div>

          <div className="card-footer pt-4" id="kt_chat_messenger_footer">
            <TextareaAutosize
              type="text"
              placeholder="Type a message"
              className="form-control form-control-solid mb-3"
              value={text}
            />
            <div className="d-flex flex-stack">
              <div className="d-flex align-items-center me-2">
                <button
                  className="btn btn-sm btn-icon btn-active-light-primary me-1"
                  type="button"
                  disabled={true}
                >
                  <i className="bi bi-upload fs-3"></i>
                </button>
              </div>
              <button
                className="btn btn-primary"
                type="button"
                disabled={true}
                style={{
                  opacity: 0.4,
                }}
                onClick={() => null}
              >
                Send
              </button>
            </div>
          </div>

        </div>
      </>
    )
  }

}
