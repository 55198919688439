import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import Companies from "./components/tables/Companies";
import Documents from "./components/tables/Documents";
import Permissions from "../utils/Permissions";

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            {
              (AuthManager.currentUser.user.role === "admin") || (AuthManager.currentUser.user.role === "partner_member") ?
                <Companies
                  title={"Latest Signups"}
                  showSearch={false}
                  showPagination={false}
                />
              :
                Permissions.hasViewPermission(Permissions.DOCUMENTS) &&
                <Documents
                  title={"Latest Documents"}
                  showSearch={false}
                  showPagination={false}
                />
            }

          </div>
        </div>
      </>
    )
  }

}
