import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

export default class CheckCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a
          className="btn btn-icon btn-light btn-light-primary btn-sm"
          style={{ margin: 3 }}
          onClick={(e) => this.props.onCheckClicked()}
        >
          <i className="fa fa-check"></i>
        </a>
      </Tooltip>
    );
  }
}

CheckCell.defaultProps = {
  tooltip: "",
};
