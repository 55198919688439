import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table';
import { toast } from 'react-toastify';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import logo from './logo.svg';

import Api from './constants/Api'
import General from './constants/General'

import './App.css';
import './assets/plugins/global/plugins.bundle.css';
import './assets/plugins/plugins.bundle.css';
import './assets/css/style.bundle.css';
import './assets/css/datatables.bundle.css';
import './assets/css/custom.css';

import Pagination from "./pages/components/tables/Pagination";

import MetaTags from './pages/components/meta/MetaTags';
import BackDropLoader from './pages/components/common/BackDropLoader';

import AuthApp from "./pages/App";
import Login from "./pages/Login";
import ResetPassword from './pages/ResetPassword';

import GeneralUtil from './utils/General';
import Backend from './utils/Backend';
import Notify from './utils/Notify';

Object.assign(ReactTableDefaults, {
  minRows: 10,
  PaginationComponent: Pagination
})

toast.configure()

//Made a common function to set the branding details
const _updateBrandingDetails = () => {
    document.querySelector(":root")?.style?.setProperty("--bs-primary", window.General.Branding?.colors?.primary)
    document.querySelector(":root")?.style?.setProperty("--default-text-on-primary",window.General.Branding?.colors?.text_on_primary)
    document.querySelector(":root")?.style?.setProperty("--default-secondary-bg-color", window.General.Branding?.colors?.secondary)
    document.querySelector(":root")?.style?.setProperty("--default-text-on-secondary",window.General.Branding?.colors?.text_on_secondary)
    document.querySelector(":root")?.style?.setProperty("--default-secondary-bg-color-active",window.General.Branding?.colors?.tertiary)
    document.querySelector(":root")?.style?.setProperty("--default-icon-fill-color-quaternary",window.General.Branding?.colors?.quaternary)

    document.querySelector(":root")?.style?.setProperty("--default-icon-fill-color-quinary-active", window.General.Branding?.colors?.quinary)
    document.querySelector(":root")?.style?.setProperty("--bs-success",window.General.Branding?.colors?.primary)
    document.querySelector(":root")?.style?.setProperty("--default-light-primary-bg-color", `${GeneralUtil.hexToRgba(window.General.Branding?.colors?.primary, 0.1)}`)
    document.querySelector(":root")?.style?.setProperty("--default-bs-primary-text-color", window.General.Branding?.colors?.primary)
    document.querySelector(":root")?.style?.setProperty("--bs-active-primary", window.General.Branding?.colors?.primary)
    document.querySelector(":root")?.style?.setProperty("--bs-primary-hover", `${GeneralUtil.hexToRgba(window.General.Branding?.colors?.primary, 0.8)}`)
}

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this._getBranding(); // calling the get branding API

    let scripts = []

    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/plugins/global/plugins.bundle.js'
      )
    )
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/js/scripts.bundle.js'
      )
    )

    setTimeout(() => {
      this._addScripts(scripts)
    }, 1000)
  }

  _getBranding() {
    this.setState({ isLoading: true });
    Backend.getBranding().then((branding) => {
      window.General.Branding = branding;
      _updateBrandingDetails();
      this.setState({ isLoading: false });
    })
    .catch(e => {
      this.setState({ isLoading: false });
      Notify.error(e.message);
    });
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    const {isLoading} = this.state
    return (
      <>
        <MetaTags />
        <BrowserRouter>
          <BackDropLoader isLoading={isLoading}/>
          <Switch>
            <UnauthenticatedRoute exact path="/login" component={Login} />
            <UnauthenticatedRoute exact path="/reset-password" component={ResetPassword} />
            <AuthenticatedRoute path="/" component={AuthApp} />
          </Switch>
        </BrowserRouter>
      </>
    )
  }

}
