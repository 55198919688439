import React from 'react';
import Dropzone from 'react-dropzone-uploader'

import ContentLoader from "react-content-loader";

import 'react-dropzone-uploader/dist/styles.css'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

const HEIGHT = 100

export default class CustomDropzone extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false
    }

    this.uploading = 0
  }

  _getUploadParams({ file, meta }){
    const body = new FormData()
    body.append('file', file)

    for(var key in this.props.params){
      body.append(key, this.props.params[key])
    }

    let headers = {}
    if(AuthManager.accessToken){
      headers["Authorization"] = "Bearer " + AuthManager.accessToken
    }

    let url = window.Api.Files

    return {
      file,
      body,
      headers,
      url
    }
  }

  // called every time a file's `status` changes
  _handleChangeStatus({ meta, file, xhr }, status){
    let complete = status === 'done' || status === "exception_upload"

    this.setState({ loading: !complete })
    if(status === "done"){
      let image = JSON.parse(xhr.responseText)
      this.props.onUploaded(image)
      this.uploading -= 1
      if(this.uploading === 0){
        this.props.onUploadFinished()
      }
    }
    if(status === "rejected_file_type"){
      Notify.error("You can only upload image/pdf files")
      this.setState({ loading: false })
    }
    if(status === "aborted"){
      this.uploading -= 1
      if(this.uploading === 0){
        this.props.onUploadFinished()
      }
    }
    if(status === "preparing" || status === "restarted"){
      this.uploading += 1
      this.props.onUploadStarted()
    }
    if(status === "removed"){
      let image = JSON.parse(xhr.responseText)
      this.props.onRemoved(image)
    }
  }


  render(){
    let {
      loading,
    } = this.state

    if(loading){
      // return (
      //   <ContentLoader speed={0.5} style={{ width: '100%', height: HEIGHT }}>
      //     <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      //   </ContentLoader>
      // )
    }
    return (
      <Dropzone
        getUploadParams={values => this._getUploadParams(values)}
        onChangeStatus={(values, status) => this._handleChangeStatus(values, status)}
        accept="image/jpg,image/jpeg,image/png,application/pdf"
        multiple={this.props.multiple}
        maxFiles={this.props.maxFiles}
        styles={{
          dropzone: { width: "100%", minHeight: HEIGHT, maxHeight: HEIGHT * 3 },
        }}
        SubmitButtonComponent={null}
        inputContent="Drop files or click here to upload"
      />
    )
  }
}

CustomDropzone.defaultProps = {
  type: 'photo',
  multiple: false,
  maxFiles: 1
}
