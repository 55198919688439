import React, { useEffect, useState } from "react";
import TextInputField from "./components/common/TextInputField";
import CustomCard from "./components/common/CustomCard";
import Select from "./components/common/Select";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PartnerValidationSchema } from "../validations/Partner";
import { Controller } from "react-hook-form";
import ShowFormError from "./components/common/ShowFormError";
import CustomPhoneInput from "./components/common/CustomPhoneInput";
import SwatchColorPicker from "./components/common/SwatchColorPicker";
import Notify from "../utils/Notify";
import Backend from "../utils/Backend";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import FilingOverview from "./components/stats/FilingOverview";
import ImageEdit from "./components/common/ImageEdit";
import CustomBar from "./components/chart/CustomBar";
import BackDropLoader from "./components/common/BackDropLoader";

const partnerTypeData = [
  { label: "Marketplace - Partner’s store brings in transactions for their clients", value: "marketplace" },
  { label: "Standard - Partner’s client each connect their own stores", value: "standard" }
];

const Partner = () => {
  const { partnerId } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const defaultValues = {
    info: {
      primary_color: window.General.Branding?.colors?.primary,
      text_on_primary_color: window.General.Branding?.colors?.text_on_primary,
      secondary_color: window.General.Branding?.colors?.secondary,
      email_background_color:
        window.General.Branding?.colors?.email_background_color,
      text_on_secondary_color:
        window.General.Branding?.colors?.text_on_secondary,
      tertiary_color: window.General.Branding?.colors?.tertiary,
      quaternary: window.General.Branding?.colors?.quaternary,
      quinary: window.General.Branding?.colors?.quinary,
      purchase: window.General.Branding?.colors?.purchase,
      sales: window.General.Branding?.colors?.sales,
      net: window.General.Branding?.colors?.net,
    },
    domains: [
      { cname: null, type: "client_dashboard" },
      { cname: null, type: "admin_dashboard" },
    ],
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(partnerId ? PartnerValidationSchema.omit(["members"]) : PartnerValidationSchema),
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'domains',
  });

  // Bar chart initial/dummy data structure
  const timePeriod = ["Jan - Feb", "Mar - Apr", "May - Jun"];
  let borderWidth = 1;
  let periodicTaxData = [
    {
      borderWidth,
      label: "Tax On Purchases",
      backgroundColor: watch("info.purchase"),
      borderColor: watch("info.purchase"),
      data: timePeriod.map(() => 300),
    },
    {
      borderWidth,
      label: "Tax On Sales",
      backgroundColor: watch("info.sales"),
      borderColor: watch("info.sales"),
      data: timePeriod.map(() => 500),
    },
    {
      borderWidth,
      label: "NET",
      backgroundColor: watch("info.net"),
      borderColor: watch("info.net"),
      data: timePeriod.map(() => -700),
    },
  ];
  let labels = timePeriod;

  const handleAddClick = (partnerObject) => {
    setLoading(true);
    handleBackend(partnerObject)
      .then((response) => {
        setLoading(false);
        Notify.success("Partner Saved");
        history.push("/partners");
      })
      .catch((e) => {
        setLoading(false);
        Notify.error(e.message);
      });
  };

  const handleBackend = (partnerObject) => {
    if (partnerId) {
      delete partnerObject.members
      return Backend.updatePartner({ ...partnerObject, id: partnerId });
    } else {
      return Backend.addPartner(partnerObject);
    }
  };

  useEffect(() => {
    const getPartnerById = () => {
      setLoading(true);
      Backend.getPartner(partnerId)
        .then((partner) => {
          setLoading(false);
          const responseData = {
            ...partner,
            info: {
              ...partner.info,
              terms_url: partner?.info?.terms_url?.replace("https://", ""),
              support_url: partner?.info?.support_url?.replace("https://", ""),
              not_found_url: partner?.info?.not_found_url?.replace("https://",""),
              website_url: partner?.info?.website_url?.replace("https://", ""),
            },
          }
          reset(responseData);
        })
        .catch((e) => {
          setLoading(false);
          Notify.error(e.message);
        });
    };

    if (partnerId) {
      getPartnerById();
    }
  }, [partnerId]);

  return (
    <>
      { loading && <BackDropLoader isLoading={loading}/> }
      <form onSubmit={handleSubmit(handleAddClick)}>
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-xxl">
              <div className="col-8">
                <CustomCard title={"Details"}>
                  <TextInputField
                    isRequired
                    label={"Partner Name"}
                    errorMsg={errors.name?.message}
                    placeholder={"Partner Name"}
                    {...register("name")}
                    outerContainerClassName = {"d-flex"}
                    labelClass={"col-lg-3"}
                  />
                  {!partnerId && <div
                    className="fv-row fv-plugins-icon-container row mt-5"
                    bis_skin_checked="1"
                  >
                    <label className="col-md-12 col-lg-3 col-form-label required">
                      Type
                    </label>
                    <div className="col-sm-12 col-lg-9 ps-1">
                      <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            options={partnerTypeData}
                            placeholder={"Select Option"}
                            getOptionLabel={(type) => type.label}
                            getOptionValue={(type) => type.value}
                            className="form-control form-control-solid h-auto c-selectbox p-0 flex-shrink-1"
                            classNamePrefix="filter-select"
                            onSelected={(option) => {
                              if (option?.value) field.onChange(option.value);
                              else field.onChange(null);
                            }}
                          />
                        )}
                      />
                      <ShowFormError message={errors?.type?.message} />
                    </div>
                  </div>}
                </CustomCard>

                <CustomCard title={"Links & Tracking Codes"}>
                  <TextInputField
                    link
                    label={"Terms URL"}
                    errorMsg={errors.info?.terms_url?.message}
                    {...register("info.terms_url")}
                    outerContainerClassName = {"d-flex"}
                    labelClass={"col-lg-3"}
                  />
                  <TextInputField
                    link
                    label={"Support URL"}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    errorMsg={errors.info?.support_url?.message}
                    {...register("info.support_url")}
                  />
                  <TextInputField
                    link
                    label={"404 URL"}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    errorMsg={errors.info?.not_found_url?.message}
                    {...register("info.not_found_url")}
                  />
                  <TextInputField
                    link
                    isRequired
                    label={"Website URL"}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    errorMsg={errors.info?.website_url?.message}
                    {...register("info.website_url")}
                  />
                  <TextInputField
                    label={`Client Dashboard GTM ID:`}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    errorMsg={errors.info?.client_gtm_id?.message}
                    {...register("info.client_gtm_id")}
                  />
                </CustomCard>

                {!partnerId && <CustomCard title={"Main Contact"}>
                  <TextInputField
                    isRequired
                    label={"First Name"}
                    placeholder={"First Name"}
                    errorMsg={errors?.members?.[0]?.user?.first_name?.message}
                    {...register("members.0.user.first_name")}
                    disabled={partnerId ? true : false}
                    outerContainerClassName = {"d-flex"}
                    labelClass={"col-lg-3"}
                  />
                  <TextInputField
                    isRequired
                    label={"Last Name"}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    placeholder={"Last Name"}
                    errorMsg={errors?.members?.[0]?.user?.last_name?.message}
                    {...register("members.0.user.last_name")}
                    disabled={partnerId ? true : false}
                  />
                  <div className="form-group mt-5 row w-100">
                    <label className={`col-lg-3 col-form-label required`}>
                      Phone
                    </label>
                    <div className="col-lg-9 my-auto">
                      <div className="react-tel-input">
                        <CustomPhoneInput
                          value={watch("members.0.user.phone_number")}
                          setPhoneNumberValue={(number) =>
                            setValue(`members.0.user.phone_number`, number)
                          }
                          clearPhoneNumberErrors={() => {
                            clearErrors(`members.0.user.phone_number`);
                          }}
                          setPhoneNumberErrors={() =>
                            setError(`members.0.user.phone_number`, {
                              message: "Invalid phone number",
                            })
                          }
                          errorMessage={errors?.members?.[0]?.user?.phone_number?.message}
                          disabled={partnerId ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <TextInputField
                    isRequired
                    label={"Email"}
                    outerContainerClassName = {"d-flex mt-5"}
                    labelClass={"col-lg-3"}
                    placeholder={"Email"}
                    type={"email"}
                    errorMsg={errors?.members?.[0]?.user?.email?.message}
                    {...register("members.0.user.email")}
                    disabled={partnerId ? true : false}
                  />
                </CustomCard>}

                <div className="card mb-8">
                  <div className="card-header border-1">
                    <div className="card-title m-0" bis_skin_checked="1">
                      <h3 className="fw-bold m-0">Branding</h3>
                    </div>
                  </div>
                  <div id="branding" className="card-body">
                    <div id="unbranded" className="row">
                      <div className="col-sm-12  col-xl-6 fields">
                        <div className="row mb-5">
                          <div className="col-4">
                            <label className="mb-0 required">Favicon</label>
                            <div className="position-relative">
                              <ImageEdit
                                type={"logo"}
                                aspectRatio={NaN}
                                cropImage={false}
                                image={watch("info.favicon.original")}
                                hidePreview={false}
                                deletable={true}
                                onUpdated={(image) => {
                                  setValue("info.favicon", image);
                                  clearErrors("info.favicon");
                                }}
                                onDeleteClicked={() => {
                                  setValue("info.favicon", {});
                                  setError("info.favicon", {
                                    message: "Favicon is required",
                                  });
                                }}
                              />
                              <ShowFormError message={errors?.info?.favicon?.message} />
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="mb-0 required">Logo Light</label>
                            <div className="position-relative">
                              <ImageEdit
                                type={"logo"}
                                aspectRatio={NaN}
                                cropImage={false}
                                image={watch("info.logo_light.original")}
                                hidePreview={false}
                                deletable={true}
                                onUpdated={(image) => {
                                  setValue("info.logo_light", image);
                                  clearErrors("info.logo_light");
                                }}
                                onDeleteClicked={() => {
                                  setValue("info.logo_light", {});
                                  setError("info.logo_light", {
                                    message: "Logo light is required",
                                  });
                                }}
                              />
                              <ShowFormError
                                message={errors?.info?.logo_light?.message}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="mb-0 required">Logo Dark</label>
                            <div className="position-relative">
                              <ImageEdit
                                type={"logo"}
                                aspectRatio={NaN}
                                cropImage={false}
                                image={watch("info.logo_dark.original")}
                                hidePreview={false}
                                deletable={true}
                                onUpdated={(image) => {
                                  setValue("info.logo_dark", image);
                                  clearErrors("info.logo_dark");
                                }}
                                onDeleteClicked={() => {
                                  setValue("info.logo_dark", {});
                                  setError("info.logo_dark", {
                                    message: "Logo dark is required",
                                  });
                                }}
                              />
                              <ShowFormError
                                message={errors?.info?.logo_dark?.message}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-5">
                          <label className="mb-0 required">Background Image</label>
                          <div className="position-relative">
                            <ImageEdit
                              type={"portrait"}
                              aspectRatio={0.66}
                              cropImage={true}
                              image={watch("info.background_image.original")}
                              hidePreview={false}
                              deletable={true}
                              onUpdated={(image) => {
                                setValue("info.background_image", image)
                                clearErrors("info.background_image")
                              }}
                              onDeleteClicked={() => {
                                setValue("info.background_image", {})
                                setError("info.background_image", { message: "Background Image is required" })
                              }}
                            />
                          </div>
                          <ShowFormError message={errors?.info?.background_image?.message} />
                      </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>Brand Colour</label>
                          <Controller
                            name="info.primary_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.primary_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.primary_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Email Background Colour
                          </label>
                          <Controller
                            name="info.email_background_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.email_background_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.email_background_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Text Colour When On Brand Colour
                          </label>
                          <Controller
                            name="info.text_on_primary_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.text_on_primary_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.text_on_primary_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Left Menu Background Colour
                          </label>
                          <Controller
                            name="info.secondary_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.secondary_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.secondary_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Left Menu Text Colour
                          </label>
                          <Controller
                            name="info.text_on_secondary_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.text_on_secondary_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.text_on_secondary_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Left Menu Active Background Colour
                          </label>
                          <Controller
                            name="info.tertiary_color"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.tertiary_color")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError
                            message={errors?.info?.tertiary_color?.message}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Left Menu Icon Inactive Colour
                          </label>
                          <Controller
                            name="info.quaternary"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.quaternary")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError message={errors?.info?.quaternary?.message} />
                        </div>
                        <div className="form-group mb-3">
                          <label className={`mb-0 required`}>
                            Left Menu Icon Active Colour
                          </label>
                          <Controller
                            name="info.quinary"
                            control={control}
                            render={({ field }) => (
                              <SwatchColorPicker
                                color={watch("info.quinary")}
                                onColorChange={(value) => {
                                  field.onChange(value.hex);
                                }}
                              />
                            )}
                          />
                          <ShowFormError message={errors?.info?.quinary?.message} />
                        </div>
                      </div>
                      <div className="col-sm-12  col-xl-6 preview">
                        <div className="favicon">
                          {watch("info.favicon.original") ? (
                            <img src={watch("info.favicon.original")} alt="favicon" />
                          ) : (
                            <span>Fav Icon will appear here</span>
                          )}
                        </div>
                        <div className="card card-custom">
                          <div className="logo light" style={{ backgroundColor: watch("info.secondary_color") }}>
                            {watch("info.logo_light.original") ? (
                              <img src={watch("info.logo_light.original")} alt="logo_light" />
                            ) : (
                              <span>Logo Light will appear here</span>
                            )}
                          </div>
                          <div className="logo dark">
                            {watch("info.logo_dark.original") ? (
                              <img src={watch("info.logo_dark.original")} alt="logo_dark" />
                            ) : (
                              <span>Logo Dark will appear here</span>
                            )}
                          </div>
                          <div className="card-body p-0 d-flex flex-column position-relative" >
                            {/* <div className="flex-grow-1 card-spacer"></div> */}
                            <FilingOverview fillColor={watch("info.quaternary")} />
                            <div className="card-rounded-bottom kt_charts_widget_12_chart">
                              <CustomBar data={periodicTaxData} labels={labels} currency={"EUR"}/>
                            </div>
                          </div>
                        </div>
                        <div className="t-color-input">
                          <div className="form-group mb-3">
                            <label className={`mb-0 required`}>
                              Purchases Colour
                            </label>
                            <Controller
                              name="info.purchase"
                              control={control}
                              render={({ field }) => (
                                <SwatchColorPicker
                                  color={watch("info.purchase")}
                                  onColorChange={(value) => {
                                    field.onChange(value.hex);
                                  }}
                                />
                              )}
                            />
                            <ShowFormError message={errors?.info?.purchase?.message} />
                          </div>
                          <div className="form-group mb-3">
                            <label className={`mb-0 required`}>Sales Colour</label>
                            <Controller
                              name="info.sales"
                              control={control}
                              render={({ field }) => (
                                <SwatchColorPicker
                                  color={watch("info.sales")}
                                  onColorChange={(value) => {
                                    field.onChange(value.hex);
                                  }}
                                />
                              )}
                            />
                            <ShowFormError message={errors?.info?.sales?.message} />
                          </div>
                          <div className="form-group">
                            <label className={`mb-0 required`}>Net Colour</label>
                            <Controller
                              name="info.net"
                              control={control}
                              render={({ field }) => (
                                <SwatchColorPicker
                                  color={watch("info.net")}
                                  onColorChange={(value) => {
                                    field.onChange(value.hex);
                                  }}
                                />
                              )}
                            />
                            <ShowFormError message={errors?.info?.net?.message} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <CustomCard title={"Domain"}>
                  {
                    fields.map((field, index) => (
                      <TextInputField
                        key={field.id}
                        isRequired
                        outerContainerClassName = {"d-flex mb-5"}
                        labelClass={"col-lg-3"}
                        label={field.type === 'client_dashboard' ? "Client Dashboard" : "Admin Dashboard"}
                        placeholder={field.type === 'client_dashboard' ? "dashboard.domain.com" : "console.domain.com"}
                        errorMsg={errors?.domains?.[index]?.cname?.message}
                        {...register(`domains.${index}.cname`)}
                      />
                    ))
                  }
                </CustomCard>

                <div className="justify-content-end d-flex mb-10">
                  <button type="submit" className="btn btn-primary ml-3" disabled={loading}>
                    {partnerId ? "Update" : "Add Partner"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Partner;
