import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
// import Edit from "./cells/Edit";
// import Delete from "./cells/Delete";
// import Pill from "./cells/Pill";
// import CompanyMembers from "./CompanyMembers";
import Document from "./cells/DocumentCell";
import Digitise from "../modals/Digitise";
import DocumentModal from "../modals/Document";
import AuthManager from "../../../utils/AuthManager";
import ViewCell from "./cells/ViewCell";
import FlagCell from "./cells/FlagCell";
import Report from "../modals/Report";
import Permissions from "../../../utils/Permissions";
import DeleteCell from "./cells/DeleteCell";
import ConfirmationModal from "../modals/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Status',
  },
  values: [
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Processing",
      value: "processing"
    },
    {
      label: "Processed",
      value: "processed"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ]
}

const COMPANY_FILTER = {
  name: {
    api: 'company_id',
    label: 'Company',
  },
  endpoint: {
    url:`${window.Api.Companies}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, COMPANY_FILTER]

class Documents extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: FILTERS
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Created At',
        id: 'created_at',
        width: 200,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <span class="text-muted fw-bold text-muted d-block fs-7">{moment(document.created_at).format('DD MMM YYYY')}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Company',
        id: 'company.name',
        width: 200,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{document.company?.name}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{document.company?.created_by?.user.email}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Name',
        id: 'file.name',
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <span
                  className="text-muted fw-bold text-muted d-block fs-7">
                  {document.file.name}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        width: 150,
        Cell: rowInfo => {
          let document = rowInfo.original
          let className = 'badge-light-dark'
          if(document.status === "processing"){
            className = 'badge-light-primary'
          }else if(document.status === "processing"){
            className = 'badge-light-primary'
          }else if(document.status === "processed"){
            className = 'badge-light-success'
          }else if(document.status === "rejected"){
            className = 'badge-light-danger'
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { document.status?.toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        width: 150,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                (AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.TRANSACTIONS)) &&
                <Document
                  tooltip={"Digitise"}
                  onDocumentClicked={() => {
                    this._showDocument(document)
                  }}
                />
              }
              {
                (document.status !== "processed" && AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.MESSAGES)) &&
                <FlagCell
                  tooltip={"Report"}
                  onFlagClicked={() => {
                    if(document.conversation) {
                      window.location.href = `/company/${document.company.id}?conversation_id=${document.conversation}#messages`
                    }else {
                      this.setState({
                        selectedDocument: document,
                        showReportModal: true
                      })
                    }
                  }}
                />
              }
              {
                (AuthManager.currentUser.user.role === "admin" && Permissions.hasViewPermission(Permissions.DOCUMENTS)) &&
                <ViewCell
                  tooltip={"View"}
                  onViewClicked={() => {
                    this.setState({
                      selectedDocument: document,
                      showDocumentModal: true
                    })
                  }}
                />
              }
              {
                (document.status !== "processed" && AuthManager.currentUser.user.role === "agent" && Permissions.hasDeletePermission(Permissions.DOCUMENTS)) &&
                <DeleteCell
                  tooltip={"Delete"}
                  iconClass={"la-trash"}
                  onDeleteClicked={() => {
                    this.setState({
                      selectedDocument: document,
                      showConfirmationModal: true
                    })
                  }}
                />
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _showDocument(document){
    Backend.getDocument(document.id).then(document => {
      this.setState({selectedDocument: document, showDigitiseModal: true})
    }).catch(e => Notify.error(e.message))
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => this._deleteDocument()}
        onHide={() => this.setState({ showConfirmationModal: false, selectedDocument: null })}
      />
    )
  }

  _deleteDocument(){
    let { selectedDocument } = this.state

    this.setState({loading: true})

    Backend.deleteDocument(selectedDocument.id)
      .then(() => {
        Notify.success('Document has been deleted')
        this.setState({
          loading: false,
          selectedDocument: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      showDigitiseModal,
      showDocumentModal,
      selectedDocument,
      showReportModal,
      filters
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Documents}`}
          noDataMessage={"No documents found"}
          title={this.props.title}
          columns={columns}
          filters={filters}
          showSearch={this.props.showSearch}
          showPagination={this.props.showPagination}
          dateRange={true}
          objects={'active'}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
        {
          showDigitiseModal &&
          <Digitise
            show={showDigitiseModal}
            document={selectedDocument}
            record={selectedDocument.data}
            onHide={() => {
              this.table.current.refresh()
              this.setState({showDigitiseModal: false, selectedDocument: null})
            }}
          />
        }
        {
          showDocumentModal &&
          <DocumentModal
            show={showDocumentModal}
            document={selectedDocument}
            onHide={() => this.setState({showDocumentModal: false, selectedDocument: null})}
          />
        }
        {
          showReportModal &&
          <Report
            show={showReportModal}
            type={"document"}
            document={selectedDocument}
            onHide={() => this.setState({showReportModal: false, selectedDocument: null})}
            onReported={() => {
              this.table.current.refresh()
              this.setState({showReportModal: false, selectedDocument: null})
            }}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )

  }

}

Documents.defaultProps = {
  title: "Documents",
  showSearch: false,
  showPagination: true
}

export default withRouter(Documents);
