import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import DeleteCell from "./cells/DeleteCell";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Permissions from "../../../utils/Permissions";
import TaxRate from "../modals/TaxRate";
import ConfirmationModal from "../modals/Confirmation";


class TaxRates extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this.table.current.refresh())
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Rate',
        id: 'type',
        Cell: rowInfo => {
          let taxRate = rowInfo.original

          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{parseFloat(taxRate.rate)*100}%</a>
            </div>
          )
        }
      },
      {
        Header: 'Country',
        id: 'country_code',
        Cell: rowInfo => {
          let taxRate = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{taxRate.country_code}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Tax Code',
        id: 'tax_code',
        Cell: rowInfo => {
          let taxRate = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{General.snakeCaseToTitleCase(taxRate.tax_code || '')}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let taxRate = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <DeleteCell
                tooltip={"Delete"}
                iconClass={"la-trash"}
                onDeleteClicked={() => {
                  this.setState({
                    showConfirmationModal: true,
                    selectedTaxRate: taxRate,
                  })
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteTaxRate() {
    let { selectedTaxRate } = this.state

    this.setState({loading: true})

    Backend.deleteTaxRate(selectedTaxRate)
      .then((response) => {
        Notify.success('Filing schedule has been deleted')
        this.table.current.refresh()
        this.setState({
          loading: false,
          selectedTaxRate: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteTaxRate()
        }}
        onHide={() => {
          this.setState({
            showConfirmationModal: false,
            selectedTaxRate: null,
          })
        }}
      />
    )
  }


  render() {
    let {
      selectedTaxRate,
      showTaxRateModal,
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.TaxRates}?standard=true`

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No Tax Rates found"}
          title={this.props.title}
          columns={columns}
          objects={'active'}
          showFilter={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              {
                Permissions.hasCreatePermission(Permissions.TAXES) &&
                <button
                  type="button"
                  className="btn btn-primary ms-5"
                  onClick={() => this.setState({showTaxRateModal: true})}
                >
                  + Add Tax Rate
                </button>
              }
            </>
          )}
        />
        {
          showTaxRateModal &&
          <TaxRate
            taxRate={General.clone(selectedTaxRate)}
            show={showTaxRateModal}
            onHide={() => this.setState({showTaxRateModal: false, selectedTaxRate: null})}
            onSuccess={() => {
              this.table.current.refresh()
              this.setState({showTaxRateModal: false, selectedTaxRate: null})
            }}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )

  }

}

TaxRates.defaultProps = {
  title: "Tax Rates",
  high_risk: false,
  allocated: null,
  showLineItems: true
}

export default withRouter(TaxRates);
