import React from "react"

import PaymentsTable from "../tables/Payments";

export default class Payments extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      companyId: props.companyId,
      bankAccountConnected: props.bankAccountConnected
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      companyId,
      bankAccountConnected
    } = this.state

    let title = `Bank Account \n Not Connected`
    if(bankAccountConnected){
      title = `Bank Account \n Connected`
    }

    return (
      <>

        <div
          className={`card bgi-position-y-bottom bank-account ${bankAccountConnected ? '' : 'bank-account-not-connected'} bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-primary mb-5 mb-xl-8`}
          style={{
            backgroundPosition: '95% 20px',
            backgroundSize: '200px auto',
            backgroundImage:`url(${require('../../../assets/media/logos/bank.png')})`
          }}
        >
          <div className="card-body d-flex flex-column justify-content-center">
            <h3 className="text-white fs-2x fw-bolder line-height-lg mb-5">
              {title}
            </h3>
            {
              bankAccountConnected &&
              <>
                <p className="text-white mt-5 w-400px">
                  Payments &amp; Refunds will be automatically debited/credited.
                </p>
              </>
            }
          </div>
        </div>

        {
          bankAccountConnected &&
          <div className="row">
            <div className="col-sm-12 col-xl-12">
              <PaymentsTable companyId={companyId}/>
            </div>
          </div>
        }
      </>
    )
  }

}
