import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import ConversationListItem from "../messages/ConversationListItem"

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import LoadingCard from "./LoadingCard";
import Conversation from "../modals/Conversation";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";

export class ConversationsList extends Component {
  constructor(props) {
    super(props);

    let preSelectedConversationId = General.getQueryParam("conversation_id");

    this.state = {
      searchTerm: "",
      companyId: props.companyId,
      preSelectedConversationId,
    };
    this.lazyLoadingList = React.createRef();
  }

  componentDidMount() {
    let { preSelectedConversationId } = this.state;

    if (!preSelectedConversationId) {
      return;
    }

    Backend.getConversation(preSelectedConversationId).then((conversation) => {
      this.props.onClick(conversation);
    });
  }

  refresh() {
    this.lazyLoadingList.current.refresh();
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
      });
    },
    1000,
    false
  );

  _renderEmptyState() {
    return (
      <div className="no-content">
        <p>Ooops, no chats found...</p>
      </div>
    );
  }

  _renderPlaceholderCards() {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </>
    );
  }

  render() {
    let {
      companyId,
      searchTerm,
      preSelectedConversationId
    } = this.state

    let className = "w-100 position-relative"

    if(AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.MESSAGES)){
      className = "position-relative"
    }

    return (
      <>
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
          <div className="card card-flush h-100">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
              <form className={className} autoComplete="off" style={{width: '80%'}}>
                <span
                  className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect
                      opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                      transform="rotate(45 17.0365 15.1223)" fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control form-control-solid ps-15"
                  name="search"
                  placeholder="Search by username or email..."
                  onChange={(e) => this._updateSearchTerm(e.target.value)}
                />
              </form>
              {
                (AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.MESSAGES)) &&
                <a className="btn btn-icon btn-primary btn-hover-light btn-md"
                   onClick={e => {
                     this.setState({showConversationModal: true})
                   }}
                >
                  <i className="fa la-edit text-white"></i>
                </a>
              }
            </div>
            <div className="card-body pt-5 message-list" id="kt_chat_contacts_body">
              <div
                className="scroll-y me-n5 pe-3 h-200px h-lg-auto" data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
                data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body" data-kt-scroll-offset="0px"
                style={{maxHeight: 700}}
              >

                <LazyLoadingList
                  ref={this.lazyLoadingList}
                  endpoint={`${window.Api.Conversations}?search_term=${searchTerm}${companyId ? `&company_id=${companyId}` : ''}`}
                  onItemUpdated={(conversations) => {
                    if (
                      this.props.selectedConversation == null &&
                      conversations.length > 0 &&
                      !preSelectedConversationId
                    ) {
                      this.props.onClick(conversations[0]);
                    }
                  }}
                  renderItem={(conversation) => {
                    return (
                      <ConversationListItem
                        conversation={conversation}
                        onUpdated={() => this.lazyLoadingList.current.refresh()}
                        onClick={() => this.props.onClick(conversation)}
                        selected={
                          this.props.selectedConversation?.id ===
                          conversation.id
                        }
                      />
                    );
                  }}
                  renderNoContent={() => this._renderEmptyState()}
                  renderInitialLoading={() => this._renderPlaceholderCards()}
                  renderLoadingMore={() => this._renderPlaceholderCards()}
                />

              </div>
            </div>
          </div>
        </div>
        {
          this.state.showConversationModal &&
          <Conversation
            show={this.state.showConversationModal}
            companyId={companyId}
            onHide={() => {
              this.setState({showConversationModal: false})
            }}
            onSuccess={() => {
              this.props.onClick(null)
              this.lazyLoadingList.current.refresh()
              this.setState({showConversationModal: false})
            }}
          />
        }
      </>
    )
  }

}

export default ConversationsList;
