import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom";

import Chart from './Chart'

import Backend from '../../../utils/Backend'
import Currency from '../../../utils/Currency'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'

export default class Profile extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: props.company
    }
  }

  render(){
    let { company } = this.state

    return (
      <>
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">

          <div className="card-header cursor-pointer">

            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Profile Details</h3>
            </div>

            {/*<a className="btn btn-primary align-self-center">Edit Profile</a>*/}

          </div>


          <div className="card-body p-9">

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Company</label>


              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-800 fs-6">{company.name}</span>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Contact Email</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{company.created_by.user.email}</span>
              </div>

            </div>

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Business Address</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{company.info.business_address?.raw || 'N/A'}</span>
              </div>

            </div>

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Establishment Address</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{company.info.establishment_address?.raw || 'N/A'}</span>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Sign-up Date</label>


              <div className="col-lg-8">
                <a href="#" className="fw-bold fs-6 text-gray-800 text-hover-primary">{moment(company.created_at).format('DD-MM-YYYY')}</a>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Inbox</label>


              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-gray-800">{company.info.inbox}</span>
                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title=""
                   data-bs-original-title="Any emails sent to this address will be scanned and have their attachments added as purchases for processing"
                   aria-label="Inbox Email"></i>
              </div>

            </div>

          </div>

        </div>
      </>
    )
  }
}
