import * as yup from "yup";
import General from "../utils/General";

const phoneNumberValidation = yup
  .string()
  .trim()
  .required("Phone number is required")
  .test("is-valid-phone", "Invalid phone number", (value) =>
    General.isPhoneNumberValid(value)
  );

const urlValidation = yup
  .string()
  .trim()
  .url("It must be a valid url")
  .nullable()
  .optional()
  .transform((value) => General.toggleHttps(value));

const logoAndFaviconValidation = yup
  .mixed()
  .required("Required")
  .test("has-id", "Required", (value) => {
    return value?.length;
  });

export const PartnerValidationSchema = yup.object().shape({
  name: yup.string().trim().required("Partner name is required"),
  type: yup.string().trim().required("Type is required"),
  
  members: yup.array().of(
    yup.object().shape({
      user: yup.object().shape({
        first_name: yup.string().trim().required("First name is required").max(255, "First name must be less than or equal to 255 characters").matches(/^[a-zA-Z\s']+$/, "First name can only contain letters, spaces, and single quotes"),
        last_name: yup.string().trim().required("Last name is required").max(255,"Last name must be less than or equal to 255 characters").matches(/^[a-zA-Z\s']+$/, "Last name can only contain letters, spaces, and single quotes"),
        phone_number: phoneNumberValidation,
        email: yup
          .string()
          .email("Invalid email address")
          .required("Email is required")
          .matches(/@[^.]*\./, "Invalid email address"),
      }),
    })
  ),

  info: yup.object().shape({
    terms_url: urlValidation,
    support_url: urlValidation,
    not_found_url: urlValidation,
    website_url: yup
      .string()
      .trim()
      .required("Website url is required")
      .url("It must be a valid url")
      .transform((value) => General.toggleHttps(value)),
    client_gtm_id: yup.string().nullable().optional().transform((value) => {
      if(!value?.trim()?.length){
        return null
      } return value
    }),

    favicon: logoAndFaviconValidation.transform((value) => value?.id),
    logo_light: logoAndFaviconValidation.transform((value) => value?.id),
    logo_dark: logoAndFaviconValidation.transform((value) => value?.id),
    background_image: logoAndFaviconValidation.transform((value) => value?.id),

    primary_color: yup.string().trim().required("Primary color is required"),
    secondary_color: yup
      .string()
      .trim()
      .required("Secondary color is required"),
    tertiary_color: yup.string().trim().required("Tertiary color is required"),
    text_on_primary_color: yup
      .string()
      .trim()
      .required("Text on primary color is required"),
    text_on_secondary_color: yup
      .string()
      .trim()
      .required("Text on secondary color is required"),
    email_background_color: yup
      .string()
      .trim()
      .required("Email Background color is required"),
    quaternary: yup
      .string()
      .trim()
      .required("Left Menu Icon Inactive Colour is required"),
    purchase: yup.string().trim().required("Purchase Colour is required"),
    sales: yup.string().trim().required("Sales Colour is required"),
    net: yup.string().trim().required("Net Colour is required"),
  }),

  domains: yup.array().of(
    yup.object().shape({
      cname: yup
        .string()
        .trim()
        .required("Domain name is required")
        .test("is-valid-domain", "It must be a valid domain", (value) =>
          General.checkValidUrlFunction(value)
        ),
      type: yup.string().required(),
    })
  ),
});
