import React from "react"

const ShowFormError= ({ message }) => {
  if (message?.length) {
    return <span className="text-danger mt-2">{message}</span>
  }
  return <></>
}

export default ShowFormError
