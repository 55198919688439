import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

window.General = {};
window.General.Branding = {};

window.General.Site = env.REACT_APP_SITE;
window.General.Dashboard = env.REACT_APP_DASHBOARD;

