import React from "react"
import Dropzone from 'react-dropzone';

// import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class FileUploader extends React.Component {
  constructor(props){
    super(props)

    this.onDrop = (files) => {
      this.setState({files}, () => this._onFileChange())
    };

    this.state = {
      files: [],
      file: props.file,
      params: props.params,
      endpoint: props.endpoint,
      placeholder: props.placeholder
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e) {
    let {
      files,
      endpoint,
      params
    } = this.state


    let fileIds = []
    if(files.length < 1){
      return
    }
    this.setState({ loading: true })
    this.props.onUploading()
    if(!this.props.canUpload){
      return
    }

    for(let file of files){
      Backend.uploadFile(file, endpoint, params)
        .then(file => {
          fileIds.push(file.id)
        })
        .catch(error => {
          this.setState({ loading: false })
          alert(error.message)
        })
    }
    this.props.onUploaded(fileIds)
  }

  render() {

    let {
      loading,
      placeholder
    } = this.state

    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name}
      </li>
    ));

    return (
      <>
        {
          files.length > 0 &&
          <>
            <h4>Files</h4>
            <ul style={{listStyle: 'none'}}>{files}</ul>
          </>
        }
        {
          files.length < 1 &&
          <Dropzone onDrop={this.onDrop}>
            {({getRootProps, getInputProps}) => (
              <>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>

                  <div className="ms-4">
                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                      {this.props.placeholder}
                    </h3>
                    <span className="fs-7 fw-semibold text-gray-400">
                      Click here or drag your files to upload
                    </span>
                  </div>
                </div>
              </>
            )}
          </Dropzone>
        }
      </>
    )
  }
}


FileUploader.defaultProps = {
  canUpload: true,
  className: "",
  placeholder: "Choose file"
}
