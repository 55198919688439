import React from "react";
import Modal from 'react-bootstrap/Modal'
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";
import Select from "../common/Select";
import General from "../../../utils/General";
import RuleInput from "../rules/RuleInput";

const TARGET_GROUP_MONETARY = ["tax", "total", "subtotal"]
const TARGET_GROUP_TAX = ["tax_rate"];
const TARGET_GROUP_ENTITY = ["entity"];
const TARGET_GROUP_ISSUED_AT = ["issued_at"];

export default class Rule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      rule: props.rule || {sub_rules: []},
    }
  }

  _validateMonetary(target, comparison, value, index=null){
    let validComparison = ["equals", "not_equals", "greater_than", "greater_than_or_equals", "less_than", "less_than_or_equals"]

    if(index != null){
      if (target == null || target.length === 0) {
        return `Please select a target at ${General.toOrdinal(index+1)} sub rule`
      }else if (comparison == null || comparison.length === 0) {
        return `Please select a comparison at ${General.toOrdinal(index+1)} sub rule`
      }
    }

    if(!validComparison.includes(comparison)){
      return `Invalid comparison selected${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}, it should be one of these ${validComparison}`
    }

    if(value == null){
      return `Please specify a value${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}`
    }

    return null
  }

  _validateTax(target, comparison, value, index=null){
    let error = null

    if(comparison !== "unrecognised"){
      error = this._validateMonetary(target, comparison, value)
    }

    if(error){
      return error
    }

    return null
  }

  _validateEntity(target, comparison, value, index=null){
    let validComparison = ["business", "customer"]

    if(index != null){
      if (target == null || target.length === 0) {
        return `Please select a target at ${General.toOrdinal(index+1)} sub rule`
      }else if (comparison == null || comparison.length === 0) {
        return `Please select a comparison at ${General.toOrdinal(index+1)} sub rule`
      }
    }

    if(!validComparison.includes(comparison)){
      return `Invalid comparison selected${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}, it should be one of these ${validComparison}`
    }

    if(value){
      return `Value must not be specified for this target${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}`
    }

    return null
  }
  _validateIssuedAt(target, comparison, value, index=null){
    let validComparison = ["equals", "not_equals", "greater_than", "greater_than_or_equals", "less_than", "less_than_or_equals"]

    if(index != null){
      if (target == null || target.length === 0) {
        return `Please select a target at ${General.toOrdinal(index+1)} sub rule`
      }else if (comparison == null || comparison.length === 0) {
        return `Please select a comparison at ${General.toOrdinal(index+1)} sub rule`
      }
    }

    if(!validComparison.includes(comparison)){
      return `Invalid comparison selected${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}, it should be one of these ${validComparison}`
    }

    if(value == null){
      return `Please specify a value${index != null ? ` at ${General.toOrdinal(index+1)} sub rule` : ''}`
    }

    return null
  }

  _isFormValid() {
    let { target, comparison, value, risk, description, sub_rules } = this.state.rule

    let error = null

    if (target == null || target.length === 0) {
      error = 'Please select a target'
    }else if (comparison == null || comparison.length === 0) {
      error = 'Please select a comparison'
    }else if (risk == null || risk.length === 0) {
      error = 'Please select a risk'
    }else if (description == null || description.length === 0) {
      error = 'Please enter a description for this rule'
    }

    if(!error){
      if(TARGET_GROUP_MONETARY.includes(target)){
        error = this._validateMonetary(target, comparison, value)
      }else if(TARGET_GROUP_TAX.includes(target)){
        error = this._validateTax(target, comparison, value)
      }else if(TARGET_GROUP_ENTITY.includes(target)){
        error = this._validateEntity(target, comparison, value)
      }else if(TARGET_GROUP_ISSUED_AT.includes(target)){
        error = this._validateIssuedAt(target, comparison, value)
      }
    }

    if(!error){
      sub_rules.map((subRule, index) => {
        if(TARGET_GROUP_MONETARY.includes(subRule.target)){
          error = this._validateMonetary(subRule.target, subRule.comparison, subRule.value, index)
        }else if(TARGET_GROUP_TAX.includes(subRule.target)){
          error = this._validateTax(subRule.target, subRule.comparison, subRule.value, index)
        }else if(TARGET_GROUP_ENTITY.includes(subRule.target)){
          error = this._validateEntity(subRule.target, subRule.comparison, subRule.value, index)
        }else if(TARGET_GROUP_ISSUED_AT.includes(subRule.target)){
          error = this._validateIssuedAt(subRule.target, subRule.comparison, subRule.value, index)
        }else{
          error = `Please specify a target on the ${General.toOrdinal(index+1)} rule`
        }
      })
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddRule() {
    if (!this._isFormValid()) {
      return
    }
    if (this.state.rule.id) {
      this._updateRule()
    } else {
      this._addRule()
    }
  }

  _addRule() {
    let { rule } = this.state
    this.setState({ loading: true })
    Backend.addRule(rule)
      .then((rule) => {
        Notify.success('Rule Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(rule)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _updateRule() {
    let { rule } = this.state
    this.setState({ loading: true })
    Backend.updateRule(rule)
      .then((rule) => {
        Notify.success('Rule Updated Successfully')
        this.setState({ loading: false })
        this.props.onAdded(rule)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderSubRules(){
    let {
      rule
    } = this.state

    return (
      <>
        {rule.sub_rules.map((subRule, index) => {
          return (
            <div className="row fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">

              <RuleInput
                rule={subRule}
                onUpdated={updatedRule => {
                  rule.sub_rules[index] = updatedRule
                  this.setState({rule})
                }}
              />

              <div className="col-1">
                <a
                  className="btn btn-icon btn-sm btn-light-danger me-2 mt-10"
                  onClick={() => {
                    rule.sub_rules.splice(index-1, 1)
                    this.setState({rule})
                  }}
                >
                  <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="" data-bs-original-title="Delete">
                    <i className="fa fa-trash"></i>
                  </span>
                </a>
              </div>

            </div>
          )
        })}
      </>
    )
  }

  render() {
    let {
      show,
      rule,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          size={'lg'}
          className={''}
          dialogClassName={"modal-dialog"}
        >
          <Modal.Header>
            <h3 className="modal-title">Add Rule</h3>
          </Modal.Header>

          <Modal.Body>

            <div className="row">
              <div className="d-flex flex-column me-n7 pe-7">
                <div className="row fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <RuleInput
                    rule={rule}
                    onUpdated={rule => this.setState({rule})}
                  />
                  <div className="col-1 my-auto text-center">
                    <div className="fs-1 mt-8 text-gray-600">=</div>
                  </div>
                  <div className="col-2">
                    <label className="fw-semibold fs-6 mb-2">Risk</label>
                    <Select
                      value={rule.risk}
                      options={Array.from(new Array(10), (val, index) => {
                        return {label: index + 1, value: index + 1}
                      })}
                      placeholder={'Risk'}
                      getOptionLabel={comparison => comparison.label}
                      getOptionValue={comparison => comparison.value}
                      className="filter-solid custom-async-select__container"
                      classNamePrefix="custom-async-select"
                      onSelected={option => {
                        if(option) {
                          rule.risk = option.value
                        }
                        this.setState({rule})
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fv-row mb-7 fv-plugins-icon-container mt-10">
              <label className="fw-semibold fs-6 mb-2">Description</label>
              <textarea
                className="form-control mb-3 mb-lg-0"
                placeholder="Type your description for this rule here..."
                rows="5"
                value={rule.description}
                onChange={e => {
                  rule.description = e.target.value
                  this.setState({rule})
                }}
              />
            </div>

            <h4 className="mt-10">Additional Conditions</h4>
            <div className="row mt-7">

              <div className="d-flex flex-column me-n7 pe-7">

                {this._renderSubRules()}

              </div>

            </div>

            <button
              className="btn btn-light-primary"
              onClick={e => {
                rule.sub_rules.push({})
                this.setState({rule})
              }}
            >
              Add Condition
            </button>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.state.loading}
              onClick={() => this._handleAddRule()}
            >
              Submit
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}
