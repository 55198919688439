import React, { useState, useEffect } from "react";
import { ChromePicker } from 'react-color';

const SwatchColorPicker = (props) => {
  const [color, setColor] = useState(props.color);
  const [displaySwatchColorPicker, setDisplaySwatchColorPicker] = useState(false);

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  const displayColor = color && color.charAt(0) !== '#'
    ? `rgb(${JSON.parse(color).r}, ${JSON.parse(color).g}, ${JSON.parse(color).b}, ${JSON.parse(color).a})`
    : color;

  const renderSwatchColorPickerModal = () => {
    if (!displaySwatchColorPicker) {
      return null;
    }
    return (
      <div className="pop">
        <div
          className="mask"
          onClick={() => setDisplaySwatchColorPicker(false)}
        />
        <ChromePicker
          color={color || "#FFF"}
          onChange={(updatedColor) => {
            props.onColorChange(updatedColor);
          }}
        />
      </div>
    );
  };

  return (
    <div className="react-color v-swatch w-75">
      <div
        className={`color-box ${props.colorBoxClassName}`}
        style={{
          ...props.colorBoxStyle
        }}
      >
        <div className="input-group mb-3" onClick={() => setDisplaySwatchColorPicker(true)}>
          <div className="input-group-prepend cursor-pointer">
            <span className="input-group-text t-span-color-input" id="basic-addon1" style={{backgroundColor: color}}/>
          </div>
          <input
            type="text" className="form-control t-custom-view-color-input"
            placeholder="#FFFFFF"
            value={displayColor}
            onChange={(e) => {
              setColor(e.target.value);
              props.onColorChange(e.target.value);
            }}
            readOnly
            aria-label="Color"
          />
        </div>
        {renderSwatchColorPickerModal()}
      </div>
    </div>
  );
};

const styles = {
  transparent: {
    backgroundImage: 'linear-gradient(45deg, #e5e5e5 25%, transparent 25%), linear-gradient(135deg, #e5e5e5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e5e5e5 75%), linear-gradient(135deg, transparent 75%, #e5e5e5 75%)',
    backgroundSize: '10px 10px',
    backgroundPosition: '0 0, 5px 0, 5px -5px, 0px 5px',
    backgroundColor: '#f5f5f5'
  }
};

SwatchColorPicker.defaultProps = {
  colorBoxClassName: "",
  colorBoxStyle: {}
};

export default SwatchColorPicker;
