import React, { Component } from "react";

export default class LoadingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="loading-card shimmer mb-2 h-60px">
        <div className="shine"></div>
      </div>
    );
  }
}
