import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import TextInputField from "../common/TextInputField";

import ShopifyHelp from "./ShopifyHelp";

import { storeValidation } from "../../../validations/Store";

import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const Store = ({ show, className, onHide }) => {
  const [showShopifyHelp, setShowShopifyHelp] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(storeValidation),
  });

  const submitHandler = async (integrationData) => {
    setLoading(true);
    Backend.addIntegration(integrationData).then((response) => {
      setLoading(false);
      window.location = response.redirect_url;
    })
    .catch((e) => {
      setLoading(false);
      Notify.error(e.message);
    });
  };

  useEffect(() => {
    reset({
      type: "shopify",
      partner: AuthManager.currentUser.partner.id,
      redirect_url: `${window.location.origin}${window.location.pathname}`,
      data: {
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
    });
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={`modal-dialog modal-dialog-centered mw-550px ${className}`}
      >
        <Modal.Header>
          <h2 className="w-80 d-flex">Connect Shopify Account</h2>
          <div className="text-muted fw-bold fs-6 w-20">
            <a
              href="#"
              className="text-primary fw-bolder"
              onClick={() => setShowShopifyHelp(true)}
            >
              Help Page
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                <TextInputField
                  link
                  isRequired
                  label={"Shopify Store URL"}
                  labelClass = {"fw-bolder"}
                  placeholder = "your-shop-name.myshopify.com"
                  errorMsg={errors?.data?.shopUrl?.message}
                  {...register("data.shopUrl")}
                />
                <div className="text-muted text-start mb-4">
                  If you have connected your Shopify store to a custom domain
                  name,
                  <br />
                  you will still need to use your original Shopify store URL
                  provided <br />
                  in order to connect with Taxmatic.
                </div>
              </div>
              <div className="text-end pt-10">
                <a className="btn btn-light me-3" onClick={() => onHide()}>
                  Cancel
                </a>
                <button className="btn btn-primary" type="submit" disabled={loading}>
                  <span className="indicator-label">Connect</span>
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {
        showShopifyHelp &&
        <ShopifyHelp
          show={showShopifyHelp}
          onHide={() => setShowShopifyHelp(false)}
        />
      }
    </>
  );
};

export default Store;
