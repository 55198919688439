import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

export default class Conversation extends React.Component {

  constructor(props) {
    super(props);

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      message: {company: props.companyId}
    }
  }

  _reportTransaction(){
    let {
      message
    } = this.state

    if(!message.subject){
      Notify.error("Please enter the subject for this conversation")
      return
    }else if(!message.text){
      Notify.error("Please enter the reason for starting this conversation.")
      return
    }

    this.setState({isLoading: true})

    Backend.startConversation(message).then(e => {
      Notify.success("Message Sent Successfully")
      this.setState({isLoading: false}, () => this.props.onSuccess())
    }).catch(e => {
      Notify.error(e.message)
      this.setState({isLoading: false})
    })

  }

  render() {
    let {
      show,
      message
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
        >
          <Modal.Header>
            <Modal.Title>New Message</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="row">


              <div className="d-flex flex-column me-n7 pe-7">

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Subject</label>
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Subject"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    onChange={e => {
                      message.subject = e.target.value
                      this.setState({message})
                    }}
                  />
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">Message</label>
                  <textarea
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Type your message here please..."
                    rows="5"
                    onChange={e => {
                      message.text = e.target.value
                      this.setState({message})
                    }}
                  />
                </div>
              </div>
            </div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._reportTransaction()}
              disabled={this.state.isLoading}
            >
              Send
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}
