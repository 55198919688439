import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from "moment";

class Footer extends React.Component {

  render() {
    return (
      <>
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted fw-bold me-1">{moment().format('YYYY')}©</span>
              <a href={window.General.Branding?.urls?.website_url} target="_blank" className="text-gray-800 text-hover-primary">
                {window.General.Branding?.platform_name}
              </a>
            </div>
            <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
              <li className="menu-item">
                <a href={window.General.Branding?.urls?.website_url} target="_blank" className="menu-link px-2">About</a>
              </li>
              <li className="menu-item">
                <a href={window.General.Branding?.urls?.support} target="_blank" className="menu-link px-2">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Footer)
