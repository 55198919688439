import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import DndBaseTable from "./DndBaseTable"
import View from "./cells/ViewCell";
import Delete from "./cells/DeleteCell";
import General from "../../../utils/General";
import RoleModal from "../modals/Role";
import Rule from "../modals/Rule";
import ConfirmationModal from "../modals/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class Rules extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Priority',
        id: 'status',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let rule = rowInfo.original
          return (
            <>
              <div className="d-flex align-items-center">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{rowInfo.index+1}</a>
              </div>
            </>
          )
        }
      },
      {
        Header: 'Target',
        id: 'target',
        Cell: rowInfo => {
          let rule = rowInfo.original
          return (
            <>
              <div className="d-flex align-items-center">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{General.snakeCaseToTitleCase(rule.target)}</a>
              </div>
            </>
          )
        }
      },
      {
        Header: 'Comparison',
        id: 'comparison',
        Cell: rowInfo => {
          let rule = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <span className="badge badge-light-info fs-6">{General.snakeCaseToTitleCase(rule.comparison).toUpperCase()}</span>
            </div>
          )
        }
      },
      {
        Header: 'Value',
        id: 'value',
        Cell: rowInfo => {
          let rule = rowInfo.original
          let value = rule.value
          if(rule.target === "tax_rate"){
            value = `${rule.value * 100}%` || ''
          }
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{value}</a>
            </div>
          )
        }
      },
      {
        Header: 'Conditions #',
        id: 'name',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let rule = rowInfo.original
          return (
            <span
              className="badge badge-light-dark badge-sm fs-base m-lg-auto"
            >
              {rule.sub_rules.length}
            </span>
          )
        }
      },
      {
        Header: 'Risk',
        id: 'risk',
        Cell: rowInfo => {
          let rule = rowInfo.original
          let className = "badge-success"
          if(rule.risk > 5 && rule.risk < 8){
            className = "badge-warning"
          }else if(rule.risk >= 8){
            className = "badge-danger"
          }
          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
              {rule.risk}
            </span>
          )
        }
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let rule = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View"}
                onViewClicked={() => {
                  this.setState({rule, showRuleModal: true})
                }}
              />
              <Delete
                tooltip={"Delete"}
                iconClass={"la-trash"}
                onDeleteClicked={() => {
                  this.setState({rule, showConfirmationModal: true})
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteRule() {
    let { rule } = this.state

    this.setState({loading: true})

    Backend.deleteRule(rule)
      .then((rule) => {
        Notify.success('Rule has been deleted')
        this.setState({
          loading: false,
          rule: null,
          showConfirmationModal: false,
        }, () => this.table.current.refresh())
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _updateRule(rule){
    Backend.updateRule(rule)
  }

  _renderRuleModal() {
    let { rule, showRuleModal } = this.state

    return (
      <Rule
        show={showRuleModal}
        rule={General.clone(rule)}
        onHide={() => this.setState({ showRuleModal: false, rule: null })}
        onAdded={(rule) => {
          this.table.current.refresh()
          this.setState({ showRuleModal: false, rule: null })
        }}
      />
    )
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteRule()
        }}
        onHide={() => {
          this.setState({
            role: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <DndBaseTable
          ref={this.table}
          endpoint={`${window.Api.Rules}`}
          objects={'active'}
          noDataMessage={"No rules found"}
          title={"Rules"}
          columns={columns}
          filterValue={{objects: 'active'}}
          defaultSorted={[
            {
              id: "position",
              desc: false
            }
          ]}
          renderToolbar={() => (
            <>
              <button
                type="button"
                className="btn btn-primary ms-5"
                onClick={() => this.setState({showRuleModal: true})}
              >
                + Add Rule
              </button>
            </>
          )}
          onUpdated={rule => this._updateRule(rule)}
        />
        {this._renderRuleModal()}
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(Rules);
