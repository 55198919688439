import moment from "moment";

import React, { Component } from "react";

import "react-chat-elements/dist/main.css";
import { MessageList, MessageBox } from "react-chat-elements";

import TextareaAutosize from 'react-autosize-textarea';

import Dropzone from 'react-dropzone';

import Dropdown from 'react-bootstrap/Dropdown';

import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";
import Event from "../../../utils/Event";
import Backend from "../../../utils/Backend";

import LazyLoader from "../common/LazyLoader";
import Notify from "../../../utils/Notify";
import Document from "../modals/Document";

import Transaction from "../modals/Transaction";
import Permissions from "../../../utils/Permissions";
import Currency from "../../../utils/Currency";

export class Conversation extends Component {
  constructor(props) {
    super(props);

    this.onDrop = (files) => {
      this.setState({files}, () => this._uploadFile())
    };

    this.state = {
      endpoint: window.Api.Files,
      conversation: props.conversation,
      messages: [],
      fileIds: [],
      text: "",
      open: false,
    };

    this.lazyLoader = React.createRef();
    this.messagesEnd = React.createRef();
    this.messageList = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
      messages: []
    });
  }

  componentDidMount() {
    this.handleMessage = this._handleMessage.bind(this);
    this._getConversation()
    Event.on("MESSAGE", this.handleMessage);
  }

  componentWillUnmount() {
    Event.off("MESSAGE", this.handleMessage);
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._getConversation())
  }

  _getConversation(){
    Backend.getConversation(this.state.conversation.id).then(conversation => {
      this.setState({conversation})
    }).catch(e => Notify.error(e.message))
  }

  _handleMessage(data) {
    let { conversation } = this.state;

    const matchingConversationToken =
      data.conversation && data.conversation === conversation?.id;
    if (matchingConversationToken) {
      this._onMessage(data);
    } else {
      Event.emit("SHOW_MESSAGE", data);
    }
  }

  _onMessage(data) {
    if (data.sender.user?.id === AuthManager.currentUser.user.id) {
      return;
    }

    data.user = true;
    let { messages } = this.state;
    messages.unshift(data);
    this.setState(
      {
        messages,
        open: true,
      },
      () => this._scrollToBottom()
    );
  }

  _parseMessages(messages) {
    return messages.map((message) => {
      let senderId = message.sender.user?.id;

      let title = AuthManager.currentUser.user.id === message.sender.user?.id ? 'You' : message.sender.user?.first_name

      let messageData = {
        _id: message.id,
        text: message.text,
        dateString: moment(message.created_at).fromNow(),
        type: "text",
        title: title,
        position: AuthManager.currentUser.user.id === message.sender.user?.id ? 'right' : 'left',
        user: {
          _id: senderId,
          messageName: message.sender.user?.first_name,
          name: message.sender.user?.first_name,
          avatar: null,
        },
      };

      if(message.attachments.length > 0){
        messageData.data = {
          uri: message.attachments[0].file.url,
          status: {
            click: false,
            loading: 0,
          },
        }
        messageData.type = "file"
      }

      return messageData
    });
  }

  _scrollToBottom() {
    if(this.messagesEnd && this.messagesEnd.current){
      this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  _sendMessage() {
    let { text, messages, fileIds, conversation } = this.state;

    let data = {
      text,
    };

    if (conversation) {
      data.conversation = conversation.id;
    }

    Backend.sendMessage(data);

    this._updateMessages(data, false)
  }

  _updateMessages(message, file){
    let {text, messages} = this.state;

    messages.unshift({
      text: message.text,
      id: General.uuid(),
      sender: AuthManager.currentUser,
      date: moment().toDate(),
      attachments: message.attachments ? message.attachments : []
    });

    text = file ? text : ''
    this.setState(
      {
        messages,
        text,
        fileIds: []
      },
      () => this._scrollToBottom()
    );
  }

  _showDocument(){
    let { conversation } = this.state

    Backend.getDocument(conversation.document.id).then(document => {
      this.setState({selectedDocument: document, showDocumentModal: true})
    }).catch(e => Notify.error(e.message))
  }

  _showTransaction() {
    let { conversation } = this.state

    Backend.getTransaction(conversation.record.id).then(document => {
      this.setState({selectedTransaction: document, showTransactionModal: true})
    }).catch(e => Notify.error(e.message))
  }

  _uploadFile(){
    let {
      files,
      endpoint,
      params,
      conversation
    } = this.state


    let fileIds = []
    if(files.length < 1){
      return
    }
    this.setState({ isLoading: true })

    for(let file of files){
      Backend.uploadFile(file, endpoint, params)
        .then(file => {
          let data = {
            text: file.name,
            conversation: conversation.id,
            files: [file.id],
            attachments: [{file}],
          };

          if (conversation) {
            data.conversation = conversation.id;
          }

          Backend.sendMessage(data)
          this._updateMessages(data, true)

          this.setState({ isLoading: false })
        })
        .catch(error => {
          this.setState({ isLoading: false })
          alert(error.message)
        })
    }
  }

  _resolveConversation(){
    let data = {
      status: 'close',
      id: this.state.conversation.id
    }

    Backend.updateConversation(data).then(conversation => {
      this.setState({conversation})
      Notify.success("Conversation Resolved")
      this.props.onResolved()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      text,
      fileIds,
      messages,
      isLoading,
      showDropdown,
      conversation,
      selectedDocument,
      showDocumentModal,
      selectedTransaction,
      showTransactionModal
    } = this.state;

    let endpoint = `${window.Api.Messages}?conversation_id=${conversation.id}`;

    let title = conversation.subject;
    let subTitle = conversation.last_message.text

    if(conversation.document){
      title = 'Document Report'
      subTitle = `${conversation.document.file?.name || ''}`
    }else if(conversation.record){
      title = 'Transaction Report'
      let entity = conversation.record.entity?.type === "pos_terminal" ? "POS Terminal" : `${conversation.record.entity?.first_name || ''} ${conversation.record.entity?.last_name || ''}`
      subTitle = `${entity} - ${Currency.format(conversation.record.currency?.symbol, conversation.record.total)} - ${moment(conversation.record.issued_at).format('DD MMM YYYY')}`
    }

    let canConverse = AuthManager.currentUser.user.role === "agent" && Permissions.hasCreatePermission(Permissions.MESSAGES)

    return (
      <>
        <div className="card " id="kt_chat_messenger">

          <div className="card-header" id="kt_chat_messenger_header">
            <div className="m-lg-auto card-toolbar">
              <div className="me-n3">
                <Dropdown className="d-inline " autoClose="outside" show={showDropdown} onToggle={() => this.setState({ showDropdown: !showDropdown})}>
                  <Dropdown.Toggle
                    className="btn btn-sm btn-icon btn-active-light-primary hide-dropdown"
                    id="dropdown-autoclose-outside"
                    variant={"outline-primary"}
                    onClick={() => this.setState({ showDropdown: true})}
                  >
                    <i className="bi bi-three-dots fs-2"/>
                  </Dropdown.Toggle>
                  {
                    canConverse &&
                    <Dropdown.Menu
                      style={{width: 200, padding: 10}}
                    >
                      <div className="menu-item px-3">
                        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Actions</div>
                      </div>
                      <div className="menu-item px-3">
                        {
                          conversation.status !== "close" &&
                          <a
                            className="menu-link flex-stack px-3"
                            onClick={() => this._resolveConversation()}
                          >
                            Mark Resolved
                          </a>
                        }
                        {
                          (conversation.document && Permissions.hasViewPermission(Permissions.DOCUMENTS)) &&
                          <a
                            className="menu-link flex-stack px-3"
                            onClick={() => this._showDocument()}
                          >
                            Show Document
                          </a>
                        }
                        {
                          (conversation.record && Permissions.hasViewPermission(Permissions.TRANSACTIONS)) &&
                          <a
                            className="menu-link flex-stack px-3"
                            onClick={() => this._showTransaction()}
                          >
                            Show Transaction
                          </a>
                        }
                      </div>
                    </Dropdown.Menu>
                  }
                </Dropdown>
              </div>
            </div>
            <div className="card-title" style={{width: '100%'}}>
              <a
                href="#"
                className="menu-link flex-stack px-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_invite_friends"
              />
              <div className="d-flex justify-content-center flex-column me-3">
                <a
                  href="#"
                  className="menu-link flex-stack px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                />
                <a
                  href="#"
                  className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  onClick={e => e.preventDefault()}
                >
                  {title} <span className={`badge badge-light-${conversation.status === "open" ? 'success' : 'danger'} badge-sm fs-base m-lg-auto`}>
                    {General.toTitleCase(conversation.status)}
                  </span>
                </a>
                <div className="mb-0 lh-1">
                  <div
                    className="fw-light fs-md-base text-muted pb-3"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if(conversation.document && Permissions.hasViewPermission(Permissions.DOCUMENTS)) {
                        this._showDocument()
                      }else if(conversation.record && Permissions.hasViewPermission(Permissions.TRANSACTIONS)){
                        this._showTransaction()
                      }
                    }}
                  >
                    {subTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body" id="kt_chat_messenger_body">
            <LazyLoader
              ref={this.lazyLoader}
              headers={AuthManager.getHeaders()}
              endpoint={endpoint}
              onItemsUpdated={(messages) => {
                this.setState({ messages, isInitialLoading: false });
              }}
            >
              <>

                <div
                  className="scroll-y me-n5 pe-5 h-300px h-lg-auto"
                  data-kt-element="messages"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                  data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
                  data-kt-scroll-offset="-2px"
                  style={{overflowY: 'scroll'}}
                >
                  <MessageList
                    referance={this.messageList}
                    className='message-list'
                    customProps={{}}
                    lockable={true}
                    toBottomHeight={'0%'}
                    dataSource={this._parseMessages(messages).reverse()}
                    onDownload={message => {
                      window.open(message.data.uri, '_blank')
                    }}
                    onScroll={(e) => {
                      if (e.target.scrollTop === 0) {
                        if (this.lazyLoader.current) {
                          this.lazyLoader.current._loadMore();
                        }
                      }
                    }}
                  />
                </div>
              </>
            </LazyLoader>
            <div ref={this.messagesEnd} />
          </div>

          {
            (canConverse) &&
            <div className="card-footer pt-4" id="kt_chat_messenger_footer">
              <TextareaAutosize
                type="text"
                placeholder="Type a message"
                className="form-control form-control-solid mb-3"
                value={text}
                onChange={(e) => this.setState({ text: e.target.value })}
              />
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-2">
                  <button
                    className="btn btn-sm btn-icon btn-active-light-primary me-1"
                    type="button"
                    disabled={isLoading}
                  >
                    <Dropzone onDrop={this.onDrop}>
                      {({getRootProps, getInputProps}) => (
                        <>
                          <div {...getRootProps({className: ''})}>
                            <input {...getInputProps()} />
                            <i className="bi bi-upload fs-3"></i>
                          </div>
                        </>
                      )}
                    </Dropzone>
                  </button>
                </div>
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={!text.trim()}
                  style={{
                    opacity: !text.trim() ? 0.4 : 0.7,
                  }}
                  onClick={() => this._sendMessage()}
                >
                  Send
                </button>
              </div>
            </div>
          }

        </div>
        {
          showDocumentModal &&
          <Document
            show={showDocumentModal}
            document={selectedDocument}
            onHide={() => this.setState({showDocumentModal: false, selectedDocument: null})}
          />
        }
        {
          showTransactionModal &&
          <Transaction
            show={showTransactionModal}
            record={selectedTransaction}
            onHide={() => this.setState({showTransactionModal: false, selectedTransaction: null})}
          />
        }
      </>
    )
  }

}
