import React from "react"

import AuthManager from "../utils/AuthManager";

import TeamMembers from "./components/tables/TeamMembers";

export default class Users extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      current: "admin",
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let { current, admin, agent } = this.state

    let isAdminActive = current === "admin"
    let adminClassName = isAdminActive ? `text-active-primary active` : ""

    let agentClassName = !isAdminActive ? `text-active-primary active` : ""

    return (
      AuthManager.isPartner() ?
        <div id="kt_agent_tab" role="tabpanel">
          <TeamMembers
            type={"partner-members"}
          />
        </div>
      :
        <>
          <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 "></div>
              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold ">
                  <li className="nav-item">
                    <a
                      className={`nav-link pb-4 ${adminClassName}`}
                      data-bs-toggle="tab"
                      href="#kt_admin_tab"
                    >
                      Admin
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link pb-4 ${agentClassName}`}
                      data-bs-toggle="tab"
                      href="#kt_agent_tab"
                    >
                      Agents
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${isAdminActive ? "show active" : ""}`} id="kt_admin_tab" role="tabpanel">
              <TeamMembers
                type={"admin"}
              />
            </div>
            <div className={`tab-pane fade ${!isAdminActive ? "show active" : ""}`} id="kt_agent_tab" role="tabpanel">
              <TeamMembers
                type={"agent"}
              />
            </div>
          </div>
        </>
    )
  }

}
