import React from "react";

const CustomCard = ({
  title,
  children,
  className = "card mb-8",
  bodyClassName = "card-body",
}) => {
  return (
    <div className={className}>
      <div className="card-header border-1">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">{title}</h3>
        </div>
      </div>
      <div className={bodyClassName}>{children}</div>
    </div>
  );
};

export default CustomCard;
