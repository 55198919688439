import React from "react";

import AuthManager from "../utils/AuthManager";
import PartnersTable from "./components/tables/Partners";

export default class Partners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  render() {
    let { stats } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <PartnersTable />
          </div>
        </div>
      </>
    );
  }
}
