import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from "./BaseTable"
import LineItems from "./LineItems"
import General from "../../../utils/General";
import AddCell from "./cells/AddCell";
import DeleteCell from "./cells/DeleteCell";
import DocumentCell from "./cells/DocumentCell";
import Digitise from "../modals/Digitise";
import DocumentModal from "../modals/Document";
import revisions from "./Revisions";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import ViewCell from "./cells/ViewCell";
import Document from "./cells/DocumentCell";
import FlagCell from "./cells/FlagCell";
import Report from "../modals/Report";
import Permissions from "../../../utils/Permissions";
import EditCell from "./cells/EditCell";
import Tax from "../modals/Tax";
import ConfirmationModal from "../modals/Confirmation";

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "Local",
      value: "local"
    },
    {
      label: "OSS",
      value: "oss"
    },
    {
      label: "IOSS",
      value: "ioss"
    }
  ]
}

const FILTERS = [TYPE_FILTER]

class TaxNumbers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: FILTERS,
      company: props.company
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this.table.current.refresh())
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        type: "date",
        format: "%d %b %Y",
        Cell: rowInfo => {
          let taxDetail = rowInfo.original
          return (
            <div className="d-flex align-items-center">
							<span className="text-dark text-hover-primary fs-7">
                {moment(taxDetail.created_at).format('DD MMM YYYY')}
              </span>
            </div>
          )
        }
      },
      {
        Header: 'Country',
        id: 'country_code',
        width: 150,
        Cell: rowInfo => {
          let taxDetail = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{taxDetail.country_code}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        id: 'type',
        width: 130,
        Cell: rowInfo => {
          let taxDetail = rowInfo.original

          let className = "badge-light-dark"

          if(taxDetail.type === "oss"){
            className = "badge-light-info"
          }else if(taxDetail.type === "ioss"){
            className = "badge-light-success"
          }

          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ taxDetail.type.toUpperCase() }
						</span>
          )
        }
      },
      {
        Header: 'Tax No.',
        id: 'tax_no',
        Cell: rowInfo => {
          let taxDetail = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{taxDetail.tax_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        Cell: rowInfo => {
          let taxDetail = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{General.snakeCaseToTitleCase(taxDetail.frequency)}</a>
            </div>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let taxDetail = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                Permissions.hasEditPermission(Permissions.TAXES) &&
                <EditCell
                  tooltip={"Edit"}
                  onEditClicked={() => {
                    this.setState({
                      showTaxModal: true,
                      selectedTaxNumber: taxDetail,
                    })
                  }}
                />
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteTaxNumber() {
    let { selectedTaxNumber } = this.state

    this.setState({loading: true})

    Backend.deleteTaxNumber(selectedTaxNumber)
      .then((response) => {
        Notify.success('Tax Number has been deleted')
        this.table.current.refresh()
        this.setState({
          loading: false,
          selectedTaxNumber: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteTaxNumber()
        }}
        onHide={() => {
          this.setState({
            role: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }


  render() {
    let {
      type,
      filters,
      company,
      selectedTaxNumber,
      showTaxModal,
      selectedDocument,
      showDocumentModal,
      showDigitiseModal
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.TaxNumbers}?company_id=${company.id}`

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No tax details found"}
          title={this.props.title}
          columns={columns}
          objects={'active'}
          filters={filters}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              {
                Permissions.hasCreatePermission(Permissions.TAXES) &&
                <button
                  type="button"
                  className="btn btn-primary ms-5"
                  onClick={() => this.setState({showTaxModal: true})}
                >
                  + Add Tax No.
                </button>
              }
            </>
          )}
        />
        {
          showTaxModal &&
          <Tax
            company={company}
            tax={General.clone(selectedTaxNumber)}
            show={showTaxModal}
            onHide={() => this.setState({showTaxModal: false, selectedTaxNumber: null})}
            onSuccess={() => {
              this.table.current.refresh()
              this.setState({showTaxModal: false, selectedTaxNumber: null})
            }}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )

  }

}

TaxNumbers.defaultProps = {
  title: "Tax Numbers",
  high_risk: false,
  allocated: null,
  showLineItems: true
}

export default withRouter(TaxNumbers);
