import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import Permissions from '../../../utils/Permissions'
import General from "../../../utils/General";
import Select from "../common/Select";

export default class RoleModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      role: props.role || General.clone(Permissions.DEFAULT_ROLE),
    }
  }

  _isFormValid() {
    let { name } = this.state.role
    let error = null
    if (name == null || name.length === 0) {
      error = 'Please enter a role name'
    }
    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddRole() {
    if (!this._isFormValid()) {
      return
    }
    if (this.state.role.id) {
      this._updateRole()
    } else {
      this._addRole()
    }
  }

  _addRole() {
    let { role } = this.state
    this.setState({ loading: true })
    Backend.addRole(role)
      .then((role) => {
        Notify.success('Role Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(role)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _updateRole() {
    let { role } = this.state
    this.setState({ loading: true })
    Backend.updateRole(role)
      .then((role) => {
        Notify.success('Role Updated Successfully')
        this.setState({ loading: false })
        this.props.onAdded(role)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _selectedPermission(permission) {
    let { role } = this.state

    let permissionString = role.permissions[permission.key].join(',')

    let selectedPermission = permission.options.find((option) => {
      return option.value === permissionString
    })

    return selectedPermission ? selectedPermission.value : null
  }

  _renderPermissions() {
    return Permissions.OPTIONS.map((permission, index) => {
      return this._renderPermission(permission, index)
    })
  }

  _renderPermission(permission, index) {
    let { role } = this.state

    return (
      <div className="col-lg-6 my-5 row pb-5">
        <label className="col-lg-5 col-form-label">{permission.label}:</label>
        <div className="col-lg-7">
          <Select
            value={this._selectedPermission(permission)}
            options={permission.options}
            placeholder={'Select Permission'}
            getOptionLabel={permission => permission.label}
            getOptionValue={permission => permission.value}
            className="filter-solid custom-async-select__container"
            classNamePrefix="custom-async-select"
            onSelected={option => {
              let {label, value} = option
              role.permissions[permission.key] = value === "None" ? [] : value.split(',')
              this.setState({ role, error: null })
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    let { show, loading, error, role } = this.state

    let title = role?.id ? 'Update Role' : 'Add Role'
    let buttonTitle = role?.id ? 'Update Role' : 'Add Role'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        size="lg"
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="form-group">
              <span className="error-message">{error}</span>
            </div>
          )}

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Role Name:</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="name"
                placeholder={'Role Name'}
                className="form-control form-control-solid"
                value={role?.name}
                onChange={(e) => {
                  role.name = e.target.value
                  this.setState({ role, error: null })
                }}
              />
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr />
            </div>
            <div className="col-6">
              <span>
                <strong>Permissions</strong>
              </span>
            </div>
            <div className="col-3">
              <hr />
            </div>
          </div>

          <div className="form-group row">{this._renderPermissions()}</div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddRole()}
          >
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                  ></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
