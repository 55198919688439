import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from "./components/structure/LeftMenu";
import Header from "./components/structure/Header";
import Footer from "./components/structure/Footer";

import AuthManager from "../utils/AuthManager";
import Permissions from "../utils/Permissions";

import Dashboard from "./Dashboard";
import Companies from "./Companies";
import Company from "./Company";
import Filings from "./Filings";
import Filing from "./Filing";
import Transactions from "./Transactions";
import Sales from "./Sales";
import Purchases from "./Purchases";
import Users from "./Users";
import Rules from "./Rules";
import Settings from "./Settings";
import Documents from "./Documents";
import Roles from "./Roles";
import HighRisk from "./HighRisk";
import FilingSchedules from "./FilingSchedules";
import Packages from "./Packages";
import Partners from './Partners';
import Partner from './Partner';
import TaxRates from "./TaxRates";
import Store from './Store';


export default class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
    }
  }


  componentDidMount() {
    if(document.getElementById("root").className === "bg-body"){
      document.getElementById("root").className = "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed"
    }
  }

  _renderRoutes() {
    let routes = []

    routes.push(<Route exact path="/" component={Dashboard} />)
    routes.push(<Route exact path="/settings" component={Settings} />)

    if(Permissions.hasViewPermission(Permissions.TRANSACTIONS)){
      routes.push(<Route exact path="/transactions" component={Transactions} />)
      routes.push(<Route exact path="/sales" component={Sales} />)
      routes.push(<Route exact path="/purchases" component={Purchases} />)
      routes.push(<Route exact path="/high-risk" component={HighRisk} />)
    }

    if(Permissions.hasViewPermission(Permissions.FILINGS)){
      routes.push(<Route exact path="/filings" component={Filings} />)
      routes.push(<Route exact path="/filing/:filingId" component={Filing} />)
      routes.push(<Route exact path="/companies" component={Companies} />)
      routes.push(<Route exact path="/company/:companyId" component={Company} />)
    }

    if(Permissions.hasViewPermission(Permissions.DOCUMENTS) && AuthManager.currentUser?.partner?.type !== "marketplace"){
      routes.push(<Route exact path="/documents" component={Documents} />)
    }

    if(AuthManager.currentUser.user.role === "admin"){
      routes.push(<Route exact path="/team" component={Users} />)
      routes.push(<Route exact path="/rules" component={Rules} />)
      routes.push(<Route exact path="/filing-schedules" component={FilingSchedules} />)
      routes.push(<Route exact path="/roles" component={Roles} />)
      routes.push(<Route exact path="/packages" component={Packages} />)
      routes.push(<Route exact path="/tax-rates" component={TaxRates} />)
      routes.push(<Route exact path="/partners" component={Partners} />)
      routes.push(<Route exact path="/partners/add" component={Partner} />)
      routes.push(<Route exact path="/partners/:partnerId" component={Partner} />)
    }

    if(Permissions.hasViewPermission(Permissions.TEAM_MEMBERS)){
      routes.push(<Route exact path="/team" component={Users} />)
    }

    if(Permissions.hasViewPermission(Permissions.ROLES)){
      routes.push(<Route exact path="/roles" component={Roles} />)
    }

    if(Permissions.hasViewPermission(Permissions.PAYMENTS)){
      routes.push(<Route exact path="/packages" component={Packages} />)
    }
    if(Permissions.hasViewPermission(Permissions.INTEGRATIONS) && AuthManager.currentUser?.partner?.type === "marketplace"){
      routes.push(<Route exact path="/stores" component={Store} />)
    }


    return (
      <Switch>
        {routes.map(route => {
          return route
        })}
        <Redirect to="/" />
      </Switch>
    )
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
                style={{marginTop: -40}}
              >
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl">
                    {this._renderRoutes()}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }

}
