import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";
import CountrySelect from "../common/CountrySelect";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import ImageEdit from "../common/ImageEdit";
import Calculations from "../../../utils/Calculations";
import Currency from "../../../utils/Currency";
import Flatpickr from "react-flatpickr";
import moment from "moment";

export default class Subscription extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      selectedIndex: props.selectedIndex,
      company: props.company,
      subscription: props.company.subscription
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      subscription
    } = this.state

    let error = null

    if (subscription.total < 0) {
      error = "Please enter a valid price"
    } else if (!subscription.frequency) {
      error = "Please select a frequency"
    } else if (subscription.included_transactions_no && !subscription.transaction_threshold_charge) {
      error = "Please set the price per transaction"
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _onUpdateClick(){
    let {
      subscription
    } = this.state

    if(!this._isValid()){
      return
    }

    this.setState({loading: true})

    Backend.updateSubscription(subscription).then(response => {
      this.setState({loading: false})
      Notify.success("Subscription Updated")
      this.props.onSuccess(response)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      subscription,
      loading,
      standardForms,
      authorisedForms
    } = this.state

    let currency = subscription.currency ? {value: subscription.currency.id, label: subscription.currency.name, data: subscription.currency} : null

    let percentage = null
    if(subscription.rate){
      percentage = Math.round((subscription.rate * 100) * 100) / 100 || ''
    }else if(subscription.tax_rate){
      percentage = Math.round((subscription.tax_rate.rate * 100) * 100) / 100 || ''
    }
    let vatEnabled = percentage > 0 || subscription.enableVat

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-700px"}
        >
          <Modal.Header>
            <h2 className="w-100">Manage Subscription</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">On Platform Billing</label>
                <div className="position-relative">
                  <Select
                    value={subscription.on_platform}
                    options={[
                      {
                        label: "Yes, Issue Invoices To Companies",
                        value: true
                      },
                      {
                        label: "No, Don't Issue Invoices To Companies",
                        value: false
                      }
                    ]}
                    placeholder={"On Platform Billing"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      subscription.on_platform = type.value
                      this.setState({subscription})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="c-separator form-group row my-4 align-items-center text-center">
                <div className="col-3">
                  <hr/>
                </div>
                <div className="col-6"><span><strong>Pricing</strong></span></div>
                <div className="col-3">
                  <hr/>
                </div>
              </div>

              <div className="d-flex flex-column mb-1 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Price</label>
                <div className="position-relative">
                  <input
                    type="number"
                    value={subscription.total}
                    placeholder="0.00"
                    className="form-control form-control-solid"
                    onChange={e => {
                      subscription["total"] = e.target.value
                      this.setState({subscription})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <form className="form mb-7">
                <div className="form-group row">
                  <label className="col-2 col-form-label">Includes TAX</label>
                  <div className="col-2">
                    <span className="switch">
                      <label>
                        <input
                          type="checkbox"
                          checked={subscription.enableVat || vatEnabled}
                          onChange={e => {
                            let show = e.target.checked
                            if(show){
                              subscription.enableVat = show
                            }else{
                              subscription.enableVat = show
                              subscription.rate = null
                              subscription.tax_rate = null
                            }
                            this.setState({ subscription })
                          }}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  {
                    vatEnabled &&
                    <>
                      <div className="col-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{border: 'none'}}>%</span>
                          </div>
                          <input
                            className="form-control form-control-solid"
                            type="number"
                            min={0}
                            placeholder="0"
                            name={"vat"}
                            value={percentage}
                            onChange={(e) => {
                              subscription["rate"] = e.target.value / 100
                              this.setState({ subscription})
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                </div>
              </form>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Frequency</label>
                <div className="position-relative">
                  <Select
                    value={subscription.frequency}
                    options={[
                      {
                        label: "Monthly",
                        value: "monthly"
                      },
                      {
                        label: "Yearly",
                        value: "yearly"
                      }
                    ]}
                    placeholder={"Frequency"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      subscription.frequency = type.value
                      this.setState({subscription})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-1">Included Transactions Threshold</label>
                <div className="text-muted text-start mb-2">
                  Leave blank for unlimited.
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Included Transactions Threshold"
                    value={subscription.included_transactions_no}
                    className="form-control form-control-solid"
                    onChange={e => {
                      subscription.included_transactions_no = e.target.value
                      this.setState({subscription})
                    }}
                    onBlur={e => {
                      if(e.target.value === "") {
                        subscription.included_transactions_no = null
                        subscription.transaction_threshold_charge = null
                        this.setState({subscription})
                      }
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              {
                subscription.included_transactions_no !== null &&
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-1">Charge Per Transaction Over Threshold</label>
                  <div className="text-muted text-start mb-2">
                    Leave blank for none.
                  </div>
                  <div className="position-relative">
                    <input
                      type="number"
                      step={0.01}
                      placeholder="Charge Per Transaction Over Threshold"
                      value={parseFloat(subscription.transaction_threshold_charge)}
                      className="form-control form-control-solid"
                      onChange={e => {
                        subscription.transaction_threshold_charge = e.target.value
                        this.setState({subscription})
                      }}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
              }

              <div className="c-separator form-group row my-4 align-items-center text-center">
                <div className="col-3">
                  <hr/>
                </div>
                <div className="col-6"><span><strong>Xero</strong></span></div>
                <div className="col-3">
                  <hr/>
                </div>
              </div>

              <div className="d-flex flex-column mb-1 fv-row fv-plugins-icon-container">
                <label className=" fs-6 fw-semibold form-label mb-2">Xero Contact ID</label>
                <div className="position-relative">
                  <input
                    type="text"
                    value={subscription.xero_contact_id}
                    placeholder="Xero Contact ID"
                    className="form-control form-control-solid"
                    onChange={e => {
                      subscription.xero_contact_id = e.target.value
                      this.setState({subscription})
                    }}
                    onBlur={e => {
                      if(e.target.value === "") {
                        subscription.xero_contact_id = null
                        this.setState({subscription})
                      }
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => this._onUpdateClick()}
                >
                  <span className="indicator-label">
                    Update
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
