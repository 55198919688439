import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import FilingsTable from "./components/tables/Filings";

export default class Filings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <FilingsTable/>
          </div>
        </div>

      </>
    )
  }

}