import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom";

import Chart from './Chart'

import SubscriptionModal from "../modals/Subscription";

import Backend from '../../../utils/Backend'
import Currency from '../../../utils/Currency'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'
import Invoices from "../tables/Invoices";

export default class Subscription extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: props.company
    }
  }

  render(){
    let { company, showSubscriptionModal } = this.state

    let includedTransactionNumber = company.subscription?.included_transactions_no

    if(company.subscription?.included_transactions_no === null){
      includedTransactionNumber = 'Unlimited'
    }

    return (
      <>
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">

          <div className="card-header cursor-pointer">

            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Subscription Details</h3>
            </div>

            <a
              className="btn btn-primary align-self-center"
              onClick={() => this.setState({showSubscriptionModal: true})}
            >
              Manage Subscription
            </a>

          </div>


          <div className="card-body p-9">

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Frequency</label>


              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-800 fs-6">{General.toTitleCase(company.subscription?.frequency || '')}</span>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Price</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{Currency.format(company.subscription?.currency.symbol, company.subscription?.total)}</span>
              </div>

            </div>

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Included Transactions Threshold</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{includedTransactionNumber}</span>
              </div>

            </div>

            {
              company.subscription?.included_transactions_no !== null &&
              <div className="row mb-7">

                <label className="col-lg-4 fw-bold text-muted">Charge Per Transaction Over Threshold</label>


                <div className="col-lg-8 d-flex align-items-center">
                  <span className="fw-bolder fs-6 text-gray-800 me-2">{Currency.display(company.subscription?.currency.symbol, parseFloat(company.subscription?.transaction_threshold_charge))}</span>
                </div>

              </div>
            }


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Free Trial End Date</label>


              <div className="col-lg-8">
                <a href="#" className="fw-bold fs-6 text-gray-800 text-hover-primary">{company.subscription?.free_trial_ends_at ? moment(company.subscription?.free_trial_ends_at).format('DD-MM-YYYY') : '-'}</a>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Next Invoice Date</label>


              <div className="col-lg-8">
                <a href="#" className="fw-bold fs-6 text-gray-800 text-hover-primary">{moment(company.subscription?.next_invoice_at).format('DD-MM-YYYY')}</a>
              </div>

            </div>

          </div>

        </div>

        <Invoices
          company={company}
        />

        {
          showSubscriptionModal &&
          <SubscriptionModal
            show={showSubscriptionModal}
            company={company}
            onHide={() => this.setState({showSubscriptionModal: false})}
            onSuccess={(subscription) => {
              company.subscription = subscription
              this.setState({company, showSubscriptionModal: false})
            }}
          />
        }
      </>
    )
  }
}
