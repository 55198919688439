import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"

import View from "./cells/ViewCell";

import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import Permissions from "../../../utils/Permissions";

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "OSS",
      value: "oss"
    },
    {
      label: "IOSS",
      value: "ioss"
    },
    {
      label: "Local",
      value: "local"
    }
  ]
}

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Status',
  },
  values: [
    {
      label: "Open",
      value: "open"
    },
    {
      label: "Filed",
      value: "filed"
    }
  ]
}

const PAYMENT_STATUS_FILTER = {
  name: {
    api: 'payment',
    label: 'Payment Status',
  },
  values: [
    {
      label: "Paid",
      value: "paid"
    },
    {
      label: "Refunded",
      value: "refunded"
    },
    {
      label: "Awaiting Payment",
      value: "awaiting_payment"
    }
  ]
}

const COMPANY_FILTER = {
  name: {
    api: 'company_id',
    label: 'Company',
  },
  endpoint: {
    url:`${window.Api.Companies}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [TYPE_FILTER, STATUS_FILTER, PAYMENT_STATUS_FILTER, COMPANY_FILTER]
const COMPANY_DETAIL_FILTERS = [TYPE_FILTER, STATUS_FILTER, PAYMENT_STATUS_FILTER]

class Filings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: props.companyId ? COMPANY_DETAIL_FILTERS : FILTERS
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Period',
        id: 'period_start',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{filing.period}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{moment(filing.period_start).format('YYYY')}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        id: 'type',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let className = 'badge-light-dark'
          let label = `${filing.tax_number.country_code} ${filing.type.toUpperCase()}`
          if(filing.type === "oss"){
            className = 'badge-light-info'
          }else if(filing.type === "ioss"){
            className = 'badge-light-success'
          }
          if(filing.type === "oss" || filing.type === "ioss"){
            label = `${filing.type.toUpperCase()}`
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{label}
						</span>
          )
        }
      },
      {
        Header: 'Company',
        id: 'company.name',
        width: 200,
        Cell: rowInfo => {
          let filing = rowInfo.original
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">
                {filing.company.name}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Sales',
        id: 'sales_total',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6 flex-column">
                  {Currency.format(filing.currency.symbol, filing.sales_total)}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {Currency.format(filing.currency.symbol, filing.sales_tax)}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Purchases',
        id: 'purchases_total',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6 flex-column">
                  {Currency.format(filing.currency.symbol, filing.purchases_total)}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {Currency.format(filing.currency.symbol, filing.purchases_tax)}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Net',
        id: 'net',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          let className = filing.net <= 0 ? "badge-light-primary" : "badge-light-danger"
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
  						{Currency.format(filing.currency.symbol, filing.net)}
  					</span>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let className = filing.status === "open" ? 'badge-light-dark' : 'badge-light-success'
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { filing.status?.toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: 'Payment Status',
        id: 'payment_status',
        width: 150,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let paymentStatus = filing.payment_status
          let className = ""


          if(paymentStatus == "paid"){
            className = 'badge-dark'
          }

          if(paymentStatus == "refunded"){
            className = 'badge-primary'
          }

          if(paymentStatus == "awaiting_payment"){
            className = "badge-warning"
          }

          if(filing.status === "open"){
            return "-"
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { General.snakeCaseToTitleCase(paymentStatus).toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: '',
        id: '',
        width: 60,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let filing = rowInfo.original
          if(!Permissions.hasViewPermission(Permissions.FILINGS)){
            return null
          }
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View"}
                onViewClicked={() => {
                  this.props.history.push(`/filing/${filing.id}`)
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
      filters
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.Filings}?company_id=${this.props.companyId || ''}`

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No filings found"}
          title={"Filings"}
          objects={'active'}
          columns={columns}
          filters={filters}
          showSearch={false}
          showPagination={true}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </>
    )

  }

}

export default withRouter(Filings);
