import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
// import Edit from "./cells/Edit";
// import Delete from "./cells/Delete";
// import Pill from "./cells/Pill";
import General from "../../../utils/General";
// import CompanyMembers from "./CompanyMembers";
// import View from "./cells/View";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class Payments extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Description',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Net Amount',
        id: 'live_jobs',
        accessor: 'live_jobs',
      },
      {
        Header: 'Purchases Tax',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Sales Tax',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let company = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {/*<View*/}
              {/*  onViewClicked={() => {*/}
              {/*    window.open(`${window.General.Site}/${company.slug}`, '_blank')*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<Edit*/}
              {/*  onEditClicked={() => {*/}
              {/*    window.location.href = `/companies/${company.slug}`*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={``}
          noDataMessage={"No payment transactions found"}
          title={"Transaction History"}
          columns={columns}
          // filters={FILTERS}
          dateRange={true}
          defaultSorted={[
            // {
            //   id: "created_at",
            //   desc: true
            // }
          ]}
        />
      </>
    )

  }

}

export default withRouter(Payments);
