import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select";
import Email from "../../../utils/Email";

export default class Entity extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      type: props.type,
      entity: props.entity || {},
    }
  }

  _isFormValid() {
    let { type, first_name, last_name, email, tax_no } = this.state.entity

    let error = null

    if (type === null) {
      error = 'Please select a type'
    } else if (first_name == null || first_name.length === 0) {
      error = 'Please enter a first name'
    } else if (last_name == null || last_name.length === 0) {
      error = 'Please enter a last name'
    } else if (!Email.isValid(email)) {
      error = 'Please enter an email'
    }

    if(!error){
      if(type === "business"){
        if(tax_no === null){
          error = 'Please enter an email'
        }
      }
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddEntity(){
    if (!this._isFormValid()) {
      return
    }
    if (this.state.entity.id) {
      this._updateEntity()
    } else {
      this._addEntity()
    }
  }

  _updateEntity(){
    let { type, entity } = this.state

    this.setState({ loading: true })
    let data = General.clone(entity)

    let title = "Entity"

    if(type === "sale"){
      title = "Customer"
    }else if(type === "purchase"){
      title = "Supplier"
    }

    Backend.updateEntity(data)
      .then((updatedEntity) => {
        Notify.success(`${title} Updated Successfully`)
        this.setState({ loading: false })
        this.props.onAdded(updatedEntity)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _addEntity(){
    let { type, entity } = this.state

    this.setState({ loading: true })
    let data = General.clone(entity)

    let title = "Entity"

    if(type === "sale"){
      title = "Customer"
    }else if(type === "purchase"){
      title = "Supplier"
    }

    Backend.addEntity(data)
      .then((newEntity) => {
        Notify.success(`${title} Added Successfully`)
        this.setState({ loading: false })
        this.props.onAdded(newEntity)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      show,
      type,
      entity,
    } = this.state

    let title = "Add Entity"

    if(type === "sale"){
      title = "Add Customer"
    }else if(type === "purchase"){
      title = "Add Supplier"
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-dialog modal-dialog-centered mw-650px"}
        >
          <Modal.Header>
            <h2 className="fw-bold">{title}</h2>
          </Modal.Header>

          <Modal.Body>

            <form id="kt_modal_add_user_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

              <div className="d-flex flex-column me-n7 pe-7" id="kt_modal_add_user_scroll">

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fs-6 mb-2">Type</label>
                  <Select
                    value={entity.type}
                    options={[
                      {
                        label: "Business",
                        value: "business"
                      },
                      {
                        label: "Individual",
                        value: "individual"
                      }
                    ]}
                    placeholder={"Type"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      entity.type = type.value
                      this.setState({entity})
                    }}
                  />
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">First Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First Name"
                    value={entity.first_name}
                    onChange={(e) => {
                      entity.first_name = e.target.value
                      this.setState({ entity, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last Name"
                    value={entity.last_name}
                    onChange={(e) => {
                      entity.last_name = e.target.value
                      this.setState({ entity, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input
                    type="email"
                    name="user_email"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Email"
                    value={entity.email}
                    onChange={(e) => {
                      entity.email = e.target.value
                      this.setState({ entity, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                {
                  entity.type === "business" &&
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">TAX Number</label>
                    <input
                      type="text"
                      name="tax_no"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      placeholder="TAX Number"
                      value={entity.tax_no}
                      onChange={(e) => {
                        entity.tax_no = e.target.value
                        this.setState({ entity, error: null })
                      }}
                    />
                    <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                  </div>
                }

                <div className="fv-row">

                </div>
              </div>


              <div className="text-end pt-15" bis_skin_checked="1">
                <button
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={e => {
                    e.preventDefault()
                    this.props.onHide()
                  }}
                >
                  Discard
                </button>

                <button
                  className="btn btn-primary"
                  disabled={this.state.loading}
                  onClick={e => {
                    e.preventDefault()
                    this._handleAddEntity()
                  }}
                >
                  <span className="indicator-label">Submit</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>

              <div bis_skin_checked="1"></div>
            </form>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
