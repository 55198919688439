import * as yup from "yup";

export const storeValidation = yup
  .object()
  .shape({
    partner: yup.string().optional(),
    redirect_url: yup.string().optional(),
    type: yup.string().default("shopify").optional(),
    data: yup.object().shape({
      return_url: yup.string().optional(),
      shopUrl: yup
        .string()
        .trim()
        .required("Shopify store url is required")
        .test(
          "url-not-contain-http",
          "It must be a valid url",
          (value) => !value.includes("http")
        )
        .test(
          "is-url-valid",
          "Please enter a valid Shopify store url url",
          (value) => value?.includes(".myshopify.com")
        ),
      shop: yup.string().optional(),
    }),
  })
  .transform((schema) => {
    const schemaData = { ...schema };
    if (schema.data?.shopUrl) {
      schemaData.data.shop = `https://${schema.data.shopUrl}`;
    }
    return schemaData;
  });
