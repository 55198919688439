import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ShowFormError from "../common/ShowFormError";
import phone from "phone";

//  A custom phone input component that utilizes react-phone-input-2 with proper validation.
const CustomPhoneInput = ({
  value,
  setPhoneNumberValue,
  clearPhoneNumberErrors,
  setPhoneNumberErrors,
  errorMessage,
  ...props
}) => {
  return (
    <>
      <div>
        <PhoneInput
          country={"ie"}
          value={value}
          onChange={(data, _country, e, formattedValue) => {
            setPhoneNumberValue(formattedValue);
            // Checking if the entered number is valid
            const result = phone(formattedValue).isValid;
            // Handling error messages based on the validity result
            if (result) {
              clearPhoneNumberErrors();
            } else {
              setPhoneNumberErrors();
            }
          }}
          {...props}
        />
      </div>
      {/* Display error message if any  */}
      <ShowFormError message={errorMessage} />
    </>
  );
};
export default CustomPhoneInput;
