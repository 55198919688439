import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";
import CountrySelect from "../common/CountrySelect";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

export default class TaxRate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      taxRate: props.taxRate || {standard: true}
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      taxRate
    } = this.state

    let error = null

    if (!taxRate.rate){
      error = "Please specify the rate"
    }else if (!taxRate.tax_code){
      error = "Please select the tax code"
    }else if (!taxRate.country_code){
      error = "Please select the country"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onAddClick(){
    let {
      taxRate
    } = this.state

    if(!this._isValid()){
      return
    }

    this.setState({loading: true})

    Backend.addTaxRate(taxRate).then(response => {
      this.setState({loading: false})
      Notify.success("Filing Schedule Saved")
      this.props.onSuccess()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      taxRate,
      loading,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-550px"}
        >
          <Modal.Header>
            <h2 className="w-100">Tax Rate</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Rate</label>
                <div className="position-relative">
                  <input
                    type="number"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    step={"0.01"}
                    placeholder={"Rate"}
                    value={parseFloat(taxRate.rate)*100}
                    onChange={e => {
                      taxRate.rate = parseFloat(e.target.value/100)
                      this.setState({taxRate})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Tax Code</label>
                <div className="position-relative">
                  <Select
                    value={taxRate.tax_code}
                    options={[
                      {
                        label: "Standard",
                        value: "standard"
                      },
                      {
                        label: "Reduced",
                        value: "reduced"
                      },
                      {
                        label: "Super Reduced",
                        value: "super_reduced"
                      },
                      {
                        label: "Zero Rated",
                        value: "zero_rated"
                      }
                    ]}
                    placeholder={"Tax Code"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      taxRate.tax_code = type.value
                      this.setState({taxRate})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Country</label>
                <div className="position-relative">
                  <CountrySelect
                    isEU={true}
                    value={taxRate.country_code}
                    onSelected={country => {
                      taxRate.country_code = country.value
                      this.setState({taxRate})
                    }}
                  />
                </div>
              </div>

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => this._onAddClick()}
                >
                  <span className="indicator-label">
                    Add
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
