import React from "react";
import BarChart from "./BarChart";

const getOptions = (currency) => {
  return {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          let value = Math.round(tooltipItem.yLabel * 100) / 100;
          value = Number(value).toLocaleString("en");
          if (currency) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            }).format(value);
          } else {
            label += value;
          }

          return label;
        },
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          display: false,
          barPercentage: 1,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      point: {
        radius: 4,
        borderWidth: 5,
      },
    },
    layout: {
      padding: 10,
    },
  };
};

const CustomBar = ({ data, labels, currency }) => {
  return (
    <BarChart
      data={data}
      labels={labels}
      color={"#000"}
      options={getOptions(currency)}
    />
  );
};

export default CustomBar;
