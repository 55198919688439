import React, { Spinner } from 'react'

import Select from './Select'

export const COUNTRY_OPTIONS = [
  { value: "IE", label: "Ireland", continent: "Europe", isEU: true },
  { value: "GB", label: "United Kingdom", continent: "Europe", isEU: true },
  { value: "null", label: "──────────────────────────", disabled: true },
  { value: "AF", label: "Afghanistan", continent: "Asia" },
  { value: "AX", label: "Åland Islands", continent: "Europe" },
  { value: "AL", label: "Albania", continent: "Europe" },
  { value: "DZ", label: "Algeria", continent: "Africa" },
  { value: "AS", label: "American Samoa", continent: "Oceania" },
  { value: "AD", label: "Andorra", continent: "Europe" },
  { value: "AO", label: "Angola", continent: "Africa" },
  { value: "AI", label: "Anguilla", continent: "North America" },
  { value: "AQ", label: "Antarctica", continent: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda", continent: "North America" },
  { value: "AR", label: "Argentina", continent: "South America" },
  { value: "AM", label: "Armenia", continent: "Asia" },
  { value: "AW", label: "Aruba", continent: "North America" },
  { value: "AU", label: "Australia", continent: "Oceania" },
  { value: "AT", label: "Austria", continent: "Europe", isEU: true },
  { value: "AZ", label: "Azerbaijan", continent: "Asia" },
  { value: "BS", label: "Bahamas", continent: "North America" },
  { value: "BH", label: "Bahrain", continent: "Asia" },
  { value: "BD", label: "Bangladesh", continent: "Asia" },
  { value: "BB", label: "Barbados", continent: "North America" },
  { value: "BY", label: "Belarus", continent: "Europe" },
  { value: "BE", label: "Belgium", continent: "Europe", isEU: true },
  { value: "BZ", label: "Belize", continent: "North America" },
  { value: "BJ", label: "Benin", continent: "Africa" },
  { value: "BM", label: "Bermuda", continent: "North America" },
  { value: "BT", label: "Bhutan", continent: "Asia" },
  { value: "BO", label: "Bolivia, Plurinational State of", continent: "South America" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba", continent: "North America" },
  { value: "BA", label: "Bosnia and Herzegovina", continent: "Europe" },
  { value: "BW", label: "Botswana", continent: "Africa" },
  { value: "BV", label: "Bouvet Island", continent: "Antarctica" },
  { value: "BR", label: "Brazil", continent: "South America" },
  { value: "IO", label: "British Indian Ocean Territory", continent: "Asia" },
  { value: "BN", label: "Brunei Darussalam", continent: "Asia" },
  { value: "BG", label: "Bulgaria", continent: "Europe", isEU: true },
  { value: "BF", label: "Burkina Faso", continent: "Africa" },
  { value: "BI", label: "Burundi", continent: "Africa" },
  { value: "KH", label: "Cambodia", continent: "Asia" },
  { value: "CM", label: "Cameroon", continent: "Africa" },
  { value: "CA", label: "Canada", continent: "North America" },
  { value: "CV", label: "Cape Verde", continent: "Africa" },
  { value: "KY", label: "Cayman Islands", continent: "North America" },
  { value: "CF", label: "Central African Republic", continent: "Africa" },
  { value: "TD", label: "Chad", continent: "Africa" },
  { value: "CL", label: "Chile", continent: "South America" },
  { value: "CN", label: "China", continent: "Asia" },
  { value: "CX", label: "Christmas Island", continent: "Asia" },
  { value: "CC", label: "Cocos (Keeling) Islands", continent: "Asia" },
  { value: "CO", label: "Colombia", continent: "South America" },
  { value: "KM", label: "Comoros", continent: "Africa" },
  { value: "CG", label: "Congo", continent: "Africa" },
  { value: "CD", label: "Congo, the Democratic Republic of the", continent: "Africa" },
  { value: "CK", label: "Cook Islands", continent: "Oceania" },
  { value: "CR", label: "Costa Rica", continent: "North America" },
  { value: "CI", label: "Côte d'Ivoire", continent: "Africa" },
  { value: "HR", label: "Croatia", continent: "Europe", isEU: true },
  { value: "CU", label: "Cuba", continent: "North America" },
  { value: "CW", label: "Curaçao", continent: "North America" },
  { value: "CY", label: "Cyprus", continent: "Asia", isEU: true },
  { value: "CZ", label: "Czech Republic", continent: "Europe", isEU: true },
  { value: "DK", label: "Denmark", continent: "Europe", isEU: true },
  { value: "DJ", label: "Djibouti", continent: "Africa" },
  { value: "DM", label: "Dominica", continent: "North America" },
  { value: "DO", label: "Dominican Republic", continent: "North America" },
  { value: "EC", label: "Ecuador", continent: "South America" },
  { value: "EG", label: "Egypt", continent: "Africa" },
  { value: "SV", label: "El Salvador", continent: "North America" },
  { value: "GQ", label: "Equatorial Guinea", continent: "Africa" },
  { value: "ER", label: "Eritrea", continent: "Africa" },
  { value: "EE", label: "Estonia", continent: "Europe", isEU: true },
  { value: "ET", label: "Ethiopia", continent: "Africa" },
  { value: "FK", label: "Falkland Islands (Malvinas)", continent: "South America" },
  { value: "FO", label: "Faroe Islands", continent: "Europe" },
  { value: "FJ", label: "Fiji", continent: "Oceania" },
  { value: "FI", label: "Finland", continent: "Europe", isEU: true },
  { value: "FR", label: "France", continent: "Europe", isEU: true },
  { value: "GF", label: "French Guiana", continent: "South America" },
  { value: "PF", label: "French Polynesia", continent: "Oceania" },
  { value: "TF", label: "French Southern Territories", continent: "Antarctica" },
  { value: "GA", label: "Gabon", continent: "Africa" },
  { value: "GM", label: "Gambia", continent: "Africa" },
  { value: "GE", label: "Georgia", continent: "Asia" },
  { value: "DE", label: "Germany", continent: "Europe", isEU: true },
  { value: "GH", label: "Ghana", continent: "Africa" },
  { value: "GI", label: "Gibraltar", continent: "Europe" },
  { value: "GR", label: "Greece", continent: "Europe", isEU: true },
  { value: "GL", label: "Greenland", continent: "North America" },
  { value: "GD", label: "Grenada", continent: "North America" },
  { value: "GP", label: "Guadeloupe", continent: "North America" },
  { value: "GU", label: "Guam", continent: "Oceania" },
  { value: "GT", label: "Guatemala", continent: "North America" },
  { value: "GG", label: "Guernsey", continent: "Europe" },
  { value: "GN", label: "Guinea", continent: "Africa" },
  { value: "GW", label: "Guinea-Bissau", continent: "Africa" },
  { value: "GY", label: "Guyana", continent: "South America" },
  { value: "HT", label: "Haiti", continent: "North America" },
  { value: "HM", label: "Heard Island and McDonald Islands", continent: "Antarctica" },
  { value: "VA", label: "Holy See (Vatican City State)", continent: "Europe" },
  { value: "HN", label: "Honduras", continent: "North America" },
  { value: "HK", label: "Hong Kong", continent: "Asia" },
  { value: "HU", label: "Hungary", continent: "Europe", isEU: true },
  { value: "IS", label: "Iceland", continent: "Europe", isEU: true },
  { value: "IN", label: "India", continent: "Asia" },
  { value: "ID", label: "Indonesia", continent: "Asia" },
  { value: "IR", label: "Iran, Islamic Republic of", continent: "Asia" },
  { value: "IQ", label: "Iraq", continent: "Asia" },
  { value: "IM", label: "Isle of Man", continent: "Europe" },
  { value: "IL", label: "Israel", continent: "Asia" },
  { value: "IT", label: "Italy", continent: "Europe", isEU: true },
  { value: "JM", label: "Jamaica", continent: "North America" },
  { value: "JP", label: "Japan", continent: "Asia" },
  { value: "JE", label: "Jersey", continent: "Europe" },
  { value: "JO", label: "Jordan", continent: "Asia" },
  { value: "KZ", label: "Kazakhstan", continent: "Asia" },
  { value: "KE", label: "Kenya", continent: "Africa" },
  { value: "KI", label: "Kiribati", continent: "Oceania" },
  { value: "KP", label: "Korea, Democratic People's Republic of", continent: "Asia" },
  { value: "KR", label: "Korea, Republic of", continent: "Asia" },
  { value: "KW", label: "Kuwait", continent: "Asia" },
  { value: "KG", label: "Kyrgyzstan", continent: "Asia" },
  { value: "LA", label: "Lao People's Democratic Republic", continent: "Asia" },
  { value: "LV", label: "Latvia", continent: "Europe", isEU: true },
  { value: "LB", label: "Lebanon", continent: "Asia" },
  { value: "LS", label: "Lesotho", continent: "Africa" },
  { value: "LR", label: "Liberia", continent: "Africa" },
  { value: "LY", label: "Libya", continent: "Africa" },
  { value: "LI", label: "Liechtenstein", continent: "Europe", isEU: true },
  { value: "LT", label: "Lithuania", continent: "Europe", isEU: true },
  { value: "LU", label: "Luxembourg", continent: "Europe", isEU: true },
  { value: "MO", label: "Macao", continent: "Asia" },
  { value: "MK", label: "Macedonia, the former Yugoslav Republic of", continent: "Europe" },
  { value: "MG", label: "Madagascar", continent: "Africa" },
  { value: "MW", label: "Malawi", continent: "Africa" },
  { value: "MY", label: "Malaysia", continent: "Asia" },
  { value: "MV", label: "Maldives", continent: "Asia" },
  { value: "ML", label: "Mali", continent: "Africa" },
  { value: "MT", label: "Malta", continent: "Europe", isEU: true },
  { value: "MH", label: "Marshall Islands", continent: "Oceania" },
  { value: "MQ", label: "Martinique", continent: "North America" },
  { value: "MR", label: "Mauritania", continent: "Africa" },
  { value: "MU", label: "Mauritius", continent: "Africa" },
  { value: "YT", label: "Mayotte", continent: "Africa" },
  { value: "MX", label: "Mexico", continent: "North America" },
  { value: "FM", label: "Micronesia, Federated States of", continent: "Oceania" },
  { value: "MD", label: "Moldova, Republic of", continent: "Europe" },
  { value: "MC", label: "Monaco", continent: "Europe" },
  { value: "MN", label: "Mongolia", continent: "Asia" },
  { value: "ME", label: "Montenegro", continent: "Europe" },
  { value: "MS", label: "Montserrat", continent: "North America" },
  { value: "MA", label: "Morocco", continent: "Africa" },
  { value: "MZ", label: "Mozambique", continent: "Africa" },
  { value: "MM", label: "Myanmar", continent: "Asia" },
  { value: "NA", label: "Namibia", continent: "Africa" },
  { value: "NR", label: "Nauru", continent: "Oceania" },
  { value: "NP", label: "Nepal", continent: "Asia" },
  { value: "NL", label: "Netherlands", continent: "Europe", isEU: true },
  { value: "NC", label: "New Caledonia", continent: "Oceania" },
  { value: "NZ", label: "New Zealand", continent: "Oceania" },
  { value: "NI", label: "Nicaragua", continent: "North America" },
  { value: "NE", label: "Niger", continent: "Africa" },
  { value: "NG", label: "Nigeria", continent: "Africa" },
  { value: "NU", label: "Niue", continent: "Oceania" },
  { value: "NF", label: "Norfolk Island", continent: "Oceania" },
  { value: "MP", label: "Northern Mariana Islands", continent: "Oceania" },
  { value: "NO", label: "Norway", continent: "Europe", isEU: true },
  { value: "OM", label: "Oman", continent: "Asia" },
  { value: "PK", label: "Pakistan", continent: "Asia" },
  { value: "PW", label: "Palau", continent: "Oceania" },
  { value: "PS", label: "Palestinian Territory, Occupied", continent: "Asia" },
  { value: "PA", label: "Panama", continent: "North America" },
  { value: "PG", label: "Papua New Guinea", continent: "Oceania" },
  { value: "PY", label: "Paraguay", continent: "South America" },
  { value: "PE", label: "Peru", continent: "South America" },
  { value: "PH", label: "Philippines", continent: "Asia" },
  { value: "PN", label: "Pitcairn", continent: "Oceania" },
  { value: "PL", label: "Poland", continent: "Europe", isEU: true },
  { value: "PT", label: "Portugal", continent: "Europe", isEU: true },
  { value: "PR", label: "Puerto Rico", continent: "North America" },
  { value: "QA", label: "Qatar", continent: "Asia" },
  { value: "RE", label: "Réunion", continent: "Africa" },
  { value: "RO", label: "Romania", continent: "Europe", isEU: true },
  { value: "RU", label: "Russian Federation", continent: "Europe" },
  { value: "RW", label: "Rwanda", continent: "Africa" },
  { value: "BL", label: "Saint Barthélemy", continent: "North America" },
  { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha", continent: "Africa" },
  { value: "KN", label: "Saint Kitts and Nevis", continent: "North America" },
  { value: "LC", label: "Saint Lucia", continent: "North America" },
  { value: "MF", label: "Saint Martin (French part)", continent: "North America" },
  { value: "PM", label: "Saint Pierre and Miquelon", continent: "North America" },
  { value: "VC", label: "Saint Vincent and the Grenadines", continent: "North America" },
  { value: "WS", label: "Samoa", continent: "Oceania" },
  { value: "SM", label: "San Marino", continent: "Europe" },
  { value: "ST", label: "Sao Tome and Principe", continent: "Africa" },
  { value: "SA", label: "Saudi Arabia", continent: "Asia" },
  { value: "SN", label: "Senegal", continent: "Africa" },
  { value: "RS", label: "Serbia", continent: "Europe" },
  { value: "SC", label: "Seychelles", continent: "Africa" },
  { value: "SL", label: "Sierra Leone", continent: "Africa" },
  { value: "SG", label: "Singapore", continent: "Asia" },
  { value: "SX", label: "Sint Maarten (Dutch part)", continent: "North America" },
  { value: "SK", label: "Slovakia", continent: "Europe", isEU: true },
  { value: "SI", label: "Slovenia", continent: "Europe", isEU: true },
  { value: "SB", label: "Solomon Islands", continent: "Oceania" },
  { value: "SO", label: "Somalia", continent: "Africa" },
  { value: "ZA", label: "South Africa", continent: "Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands", continent: "Antarctica" },
  { value: "SS", label: "South Sudan", continent: "Africa" },
  { value: "ES", label: "Spain", continent: "Europe", isEU: true },
  { value: "LK", label: "Sri Lanka", continent: "Asia" },
  { value: "SD", label: "Sudan", continent: "Africa" },
  { value: "SR", label: "Suriname", continent: "South America" },
  { value: "SJ", label: "Svalbard and Jan Mayen", continent: "Europe" },
  { value: "SZ", label: "Swaziland", continent: "Africa" },
  { value: "SE", label: "Sweden", continent: "Europe", isEU: true },
  { value: "CH", label: "Switzerland", continent: "Europe", isEU: true },
  { value: "SY", label: "Syrian Arab Republic", continent: "Asia" },
  { value: "TW", label: "Taiwan, Province of China", continent: "Asia" },
  { value: "TJ", label: "Tajikistan", continent: "Asia" },
  { value: "TZ", label: "Tanzania, United Republic of", continent: "Africa" },
  { value: "TH", label: "Thailand", continent: "Asia" },
  { value: "TL", label: "Timor-Leste", continent: "Asia" },
  { value: "TG", label: "Togo", continent: "Africa" },
  { value: "TK", label: "Tokelau", continent: "Oceania" },
  { value: "TO", label: "Tonga", continent: "Oceania" },
  { value: "TT", label: "Trinidad and Tobago", continent: "North America" },
  { value: "TN", label: "Tunisia", continent: "Africa" },
  { value: "TR", label: "Turkey", continent: "Europe" },
  { value: "TM", label: "Turkmenistan", continent: "Asia" },
  { value: "TC", label: "Turks and Caicos Islands", continent: "North America" },
  { value: "TV", label: "Tuvalu", continent: "Oceania" },
  { value: "UG", label: "Uganda", continent: "Africa" },
  { value: "UA", label: "Ukraine", continent: "Europe" },
  { value: "AE", label: "United Arab Emirates", continent: "Asia" },
  { value: "US", label: "United States", continent: "North America" },
  { value: "UM", label: "United States Minor Outlying Islands", continent: "Oceania" },
  { value: "UY", label: "Uruguay", continent: "South America" },
  { value: "UZ", label: "Uzbekistan", continent: "Asia" },
  { value: "VU", label: "Vanuatu", continent: "Oceania" },
  { value: "VE", label: "Venezuela, Bolivarian Republic of", continent: "South America" },
  { value: "VN", label: "Viet Nam", continent: "Asia" },
  { value: "VG", label: "Virgin Islands, British", continent: "North America" },
  { value: "VI", label: "Virgin Islands, U.S.", continent: "North America" },
  { value: "WF", label: "Wallis and Futuna", continent: "Oceania" },
  { value: "EH", label: "Western Sahara", continent: "Africa" },
  { value: "YE", label: "Yemen", continent: "Asia" },
  { value: "ZM", label: "Zambia", continent: "Africa" },
  { value: "ZW", label: "Zimbabwe", continent: "Africa" }
]

export default class CountrySelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      value: props.value
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render(){
    let {value} = this.state

    let options = COUNTRY_OPTIONS
    if(this.props.isEU){
      options = options.filter(option => option.isEU || option.disabled)
    }

    return (
      <Select
        value={value ? value : null}
        options={options}
        placeholder="Select A Country"
        getOptionLabel={country => country.label}
        getOptionValue={country => country.value}
        isOptionDisabled={(option) => !!option._data.disabled}
        className={this.props.className}
        classNamePrefix={this.props.classNamePrefix}
        disabled={this.props.disabled}
        onSelected={country => {
          this.props.onSelected(country)
        }}
        isClearable={this.props.isClearable}
      />
    )
  }
}

CountrySelect.defaultProps = {
  className: "form-control h-auto border-0 form-control-solid c-selectbox",
  classNamePrefix: "",
  disabled: false,
  isClearable: false,
  continents: [],
  isEU: false,
}
