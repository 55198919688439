import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";

const BackDropLoader = ({ isLoading }) => {
  return (
    <Backdrop className="t-backdrop-loader" open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackDropLoader;
