import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class Document extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      document: props.document,
      show: props.show,
    }
  }

  _renderFileWindow(file){
    let re = /(?:\.([^.]+))?$/;
    let fileType = re.exec(file.url)[1];

    if(fileType === "pdf"){
      return <iframe src={file.url} width="100%" height="100%" className="d-flex flex-column me-n7 pe-7"/>
    }
    return (
      <img
        key={file.url}
        src={file.url}
        width="100%" height="100%"
        style={{ objectFit: "contain" }}
      />
    )
  }

  render() {
    let {
      show,
      document,
    } = this.state

    if(!document) return null

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={() => this.props.onHide()}
        dialogClassName={'w-100'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{document.file.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderFileWindow(document.file)}
        </Modal.Body>
      </Modal>
    )
  }
}

