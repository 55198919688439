import React from 'react'

import Currency from '../../../utils/Currency'
import General from '../../../utils/General'
import LazyLoadingList from "../common/LazyLoadingList";
import moment from "moment/moment";
import LoadingCard from "../messages/LoadingCard";

export default class Logs extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      filing: props.filing
    }
  }

  _renderEmptyState() {
    return (
      <div className="no-content">
        <p>No activity at this time...</p>
      </div>
    );
  }

  _renderPlaceholderCards() {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </>
    );
  }

  _renderLog(log){
    let text = ``;
    let user = `${log.user?.first_name} ${log.user?.last_name}`

    if(log.type === "filing"){
      if(log.subtype === "status_update"){
        let preClassName = "badge badge-light-dark"
        let postClassName = "badge badge-light-success"
        text = <>
          Filing status has changed from <span className={preClassName}>
                  {General.toTitleCase(log.revision.previous_filing_data.status)}
                </span> to <span className={postClassName}>
                  {General.toTitleCase(log.revision.latest_filing_data.status)}
                </span>
        </>
      }else if(log.subtype === "update"){
        if(log.revision){
          if(log.revision.allocated_no > 0 && log.revision.unallocated_no > 0){
            text = <><b className="text-dark">{user}</b> allocated {log.revision.allocated_no} and unallocated {log.revision.unallocated_no} transaction(s) in this filing</>
          }else{
            text = <><b className="text-dark">{user}</b> {log.revision.allocated_no > 0 ? 'allocated' : 'unallocated'} {log.revision.allocated_no > 0 ? `${log.revision.allocated_no}` : `${log.revision.unallocated_no}`} transaction(s) in this filing</>
          }
        }else{
          text = <>Filing Updated</>
        }
      }
    }else if(log.type === "record"){
      if(log.subtype === "report"){
        text = <>
          Transaction <b className="text-dark">
          {Currency.format(log.record.currency.symbol, log.record.total)} on {moment(log.record.issued_at).format('DD MMM YYYY')}
        </b> has been reported.
        </>
      }
    }else if(log.type === "integration"){
      if(log.subtype === "ingestion"){
        text = <>
          Added <b className="text-dark">{log.ingestion.succeeded_no}</b> transactions from <b className="text-dark">Shopify</b>
        </>
      }
    }

    return (
      <tr>
        <td className="min-w-400px">
          {text}
        </td>
        <td className="pe-0 text-gray-600 text-end min-w-200px">
          {moment(log.created_at).format('DD MMM YYYY, hh:mm A')}
        </td>
      </tr>
    )
  }

  render(){
    let {
      filing
    } = this.state


    return (
      <>
        <div
          className="card pt-4 mb-6 mb-xl-9"
        >
          <div className="card-header border-0">
            <div className="card-title">
              <h2>Events</h2>
            </div>
            <div className="card-toolbar"/>
          </div>

          <div
            className="card-body py-0"
          >
            <table
              className="table align-middle table-row-dashed fs-6 text-gray-600 fw-bold gy-5"
            >
              <tbody>
                <LazyLoadingList
                  ref={this.lazyLoadingList}
                  endpoint={`${window.Api.Logs}?filing_id=${filing.id}`}
                  onItemUpdated={(logs) => {

                  }}
                  renderItem={(log) => this._renderLog(log)}
                  renderNoContent={() => this._renderEmptyState()}
                  renderInitialLoading={() => this._renderPlaceholderCards()}
                  renderLoadingMore={() => this._renderPlaceholderCards()}
                />
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}
