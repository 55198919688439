import React from 'react'

import BaseTable from './BaseTable'
import View from "./cells/ViewCell";

import moment from 'moment'

export default class IngestionsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      integration: props.integration,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
							<span className="text-dark text-hover-primary fs-7">
                {moment(ingestion.created_at).format('DD MMM YYYY')}
              </span>
            </div>
          )
        }
      },
      {
        Header: 'Retrieved',
        id: 'retrieved_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.retrieved_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Processed',
        id: 'processed_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.processed_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Succeeded',
        id: 'succeeded_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.succeeded_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Failed',
        id: 'failed_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.failed_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          let className = "badge-light-dark"
          if(ingestion.status === "processing"){
            className = 'badge-light-info'
          }else if(ingestion.status === "complete"){
            className = 'badge-light-success'
          }
          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ ingestion.status.toUpperCase() }
						</span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          if(!ingestion.error_file) return null
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View Errors"}
                onViewClicked={() => {
                  window.open(`${ingestion.error_file}`, '_blank')
                }}
              />
            </div>
          )
        }
      }
    ]
  }

  render() {
    let { integration } = this.state
    const columns = this._getColumns()

    if(!integration) return null

    let title = "Ingestions"

    if(integration.type === "amazon_file"){
      title = "TAX Reports"
    }

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Ingestions}?integration_id=${integration.id}&min_retrieved_no=1`}
          noDataMessage={'No ingestions found'}
          title={title}
          columns={columns}
          objects={'active'}
          showExport={false}
          showFilter={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
        />
      </div>
    )
  }
}

IngestionsTable.defaultProps = {
  latestResults: false,
}
