import React, {useCallback, useEffect, useState} from "react";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import shopifyLogo from "../assets/media/logos/shopify.svg";
import StoreModal from "./components/modals/Store";
import AuthManager from "../utils/AuthManager";
import General from "../utils/General";
import {useHistory} from "react-router-dom";

const Store = () => {
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const history = useHistory();

  const getIntegrations = useCallback(async () => {
    try {
      return await Backend.getIntegrations(null, "shop");
    } catch (e) {
      Notify.error(e.message);
    }
  }, []);

  useEffect(() => {
    let hmac = General.getUrlParameter("hmac");
    if (hmac) {
      let code = General.getUrlParameter("code");
      let host = General.getUrlParameter("host");
      let shop = General.getUrlParameter("shop");
      let state = General.getUrlParameter("state");
      let timestamp = General.getUrlParameter("timestamp");
      let data = {
        partner: AuthManager.currentUser.partner.id,
        type: "shopify",
        redirect_url: `${window.location.origin}${window.location.pathname}`,
        data: {
          code,
          hmac,
          host,
          shop,
          state,
          timestamp,
        },
      };
      Backend.addIntegration(data).then(() => {
        Notify.success("Shopify Connected");
        getIntegrations().then((integrations) => {
          setIntegrations(integrations);
          setSelectedIntegration(integrations?.[0]);
        });
      }).catch((e) => Notify.error(e.message));
      history.replace({ pathname: window.location.pathname });
    } else {
      getIntegrations().then((integrations) => {
        setIntegrations(integrations);
        setSelectedIntegration(integrations?.[0]);
      });
    }
  }, [getIntegrations, history]);

  let connectedTitle = "Disconnected";
  if (selectedIntegration?.status === "active") {
    connectedTitle = "Connected";
  } else if (selectedIntegration?.status === "revoked") {
    connectedTitle = "Revoked";
  } else if (selectedIntegration?.status === "pending") {
    connectedTitle = "Pending";
  }

  let title = "Shopify";
  let backgroundColor = "bg-primary-shopify";
  let textColor = "text-white";
  let buttonColor = "btn-outline-white";
  let logo = {
    backgroundPosition: "100% 50px",
    backgroundSize: "200px auto",
    backgroundImage: `url(${shopifyLogo})`,
  };

  if (integrations && integrations.length < 1) {
    return (
      <>
        <div class="row gy-5 g-xl-8">
          <div class="col-xl-10 ps-xl-12">
            <div className="card-body d-flex p-0">
              <div
                className="flex-grow-1 p-10 pb-lg-10 card-rounded min-h-250px bgi-no-repeat bg-primary-shopify"
                style={logo}
              >
                <h1
                  className="text-white pt-6 pb-5 font-weight-bolder"
                  style={{ fontSize: 40 }}
                >
                  Connect your store!
                </h1>
                <p className="text-white pb-5" style={{ fontSize: 18 }}>
                  Connect your shop account
                  <br />
                  Let's start pulling your transactions and get you TAX
                  compliant.
                  <br />
                </p>
                <button
                  className={`btn btn-sm ${buttonColor}`}
                  onClick={() => setShowIntegrationModal(true)}
                >
                  Connect Shop Account
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          showIntegrationModal &&
          <StoreModal
            show={showIntegrationModal}
            onHide={() => setShowIntegrationModal(false)}
          />
        }
      </>
    );
  }

  return (
    <div
      className={`card col-sm-8 bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px ${backgroundColor} mb-5 mb-xl-8`}
      style={logo}
    >
      <div className="card-body d-flex flex-column justify-content-center">
        <h3 className={`${textColor} fs-2x fw-bolder line-height-lg mb-5`}>
          {title} Account
          <br />
          {connectedTitle}
        </h3>

        {
          selectedIntegration?.status === "active" &&
          <>
            <button
              className={`btn btn-sm ${buttonColor} w-100px`}
              onClick={(event) => {
                if (selectedIntegration?.type === "shopify") {
                  window.open(
                    `https://${selectedIntegration?.data?.shop}`,
                    "_blank"
                  );
                } else {
                  window.open(`${selectedIntegration?.data?.shop}`, "_blank");
                }
              }}
            >
              View
            </button>
          </>
        }
      </div>
    </div>
  );
};

export default Store;
